import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Learner(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LearnerIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.406 4.086a1 1 0 0 0-.812 0l-10 4.445a1 1 0 0 0 .008 1.831l3.42 1.482L5 16.996a1 1 0 0 0 .298.717c.508.49 1.14.87 1.768 1.182C8.19 19.455 9.842 20 12 20s3.81-.545 4.934-1.105c.634-.316 1.256-.683 1.766-1.18v-.002A1 1 0 0 0 19 17v-5.166l2-.866V17a1 1 0 1 0 2 0c0-2.517-.012-5.034 0-7.55a1 1 0 0 0-.594-.92zM17 12.701l-4.602 1.994a1 1 0 0 1-.796 0L7.018 12.71l-.016 3.82C7.652 17.006 9.318 18 12 18c2.684 0 4.35-.996 5-1.472V12.7Zm-5-.013L4.488 9.433 12 6.094l7.512 3.339z"
      clipRule="evenodd"
    />
  );
}
export default Learner;
