import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Timeline(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TimelineIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 4a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1m0 4a1 1 0 0 1 1-1c.808 0 1.286.605 1.523 1.004.265.444.465 1.016.628 1.603.384 1.384.6 2.814.835 4.229.254 1.518.495 2.959.79 4.021q.116.413.224.695a8 8 0 0 0 .224-.695c.295-1.062.537-2.503.79-4.021.235-1.415.45-2.845.835-4.229.163-.587.363-1.159.628-1.603C8.714 7.605 9.192 7 10 7s1.286.605 1.523 1.004c.265.444.465 1.016.628 1.603.384 1.384.6 2.814.835 4.229.253 1.518.495 2.959.79 4.021q.116.413.224.695.108-.282.224-.695c.295-1.062.537-2.503.79-4.021.235-1.415.45-2.845.835-4.229.163-.587.363-1.159.628-1.603C16.714 7.605 17.192 7 18 7s1.286.605 1.523 1.004c.265.444.465 1.016.628 1.603.327 1.177.584 2.718.828 4.187.227 1.358.428 2.734.797 4.063.15.538.293.904.42 1.116l.033.053A1 1 0 0 1 22 21c-.808 0-1.286-.605-1.523-1.004-.265-.444-.465-1.016-.628-1.603-.327-1.177-.584-2.718-.829-4.187-.226-1.358-.427-2.734-.796-4.063A8 8 0 0 0 18 9.448a8 8 0 0 0-.224.695c-.295 1.062-.537 2.503-.79 4.021-.235 1.415-.45 2.845-.835 4.229-.163.587-.363 1.159-.628 1.603C15.286 20.395 14.808 21 14 21s-1.286-.605-1.523-1.004c-.265-.444-.465-1.016-.628-1.603-.327-1.177-.584-2.718-.828-4.187-.227-1.358-.428-2.734-.797-4.063A8 8 0 0 0 10 9.448a8 8 0 0 0-.224.695c-.37 1.329-.57 2.705-.796 4.063-.245 1.469-.502 3.01-.829 4.187-.163.587-.363 1.159-.628 1.603C7.286 20.395 6.808 21 6 21s-1.286-.605-1.523-1.004c-.265-.444-.465-1.016-.628-1.603-.327-1.177-.584-2.718-.828-4.187-.227-1.358-.428-2.734-.797-4.063-.15-.538-.293-.904-.42-1.116l-.033-.053A1 1 0 0 1 1 8"
      clipRule="evenodd"
    />
  );
}
export default Timeline;
