import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Microphone(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'MicrophoneIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M9.846 1.873a3.09 3.09 0 0 1 4.308 0A3.1 3.1 0 0 1 15.09 4v7.65a3.09 3.09 0 0 1-6.18 0V4a3.1 3.1 0 0 1 .936-2.127" />
      <path d="M17.616 10.974A1 1 0 0 1 18 10.9v-.01a1 1 0 0 1 1 1v.62a7 7 0 0 1-6 6.87V21h1.09a1 1 0 0 1 0 2H9.91a1 1 0 1 1 0-2H11v-1.62a7 7 0 0 1-6-6.87v-.62a1 1 0 0 1 .29-.71 1 1 0 0 1 .71-.29 1 1 0 0 1 .71.29 1 1 0 0 1 .29.71v.63a5 5 0 1 0 10 0v-.62a1 1 0 0 1 .29-.71q.142-.14.326-.216" />
    </g>
  );
}
export default Microphone;
