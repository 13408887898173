import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowLineDown(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ArrowLineDownIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M10.995 5v11.588l-2.88-2.88a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 1 0-1.41-1.41l-2.89 2.88V5c0-.55-.45-1-1-1s-1 .45-1 1"
    />
  );
}
export default ArrowLineDown;
