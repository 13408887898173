import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Delete(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'DeleteIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M9 1a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2zm1 9a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1m5 1a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0z" />
      <path d="M2 6a1 1 0 0 0 1 1h1v12a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V7h1a1 1 0 1 0 0-2H3a1 1 0 0 0-1 1m4 13V7h12v12a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2" />
    </g>
  );
}
export default Delete;
