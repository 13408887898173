import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Legend6Nabla(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'Legend6NablaIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M14.266 19.71c-1.019 1.72-3.513 1.72-4.532 0L3.37 8.963C2.333 7.212 3.598 5 5.636 5h12.728c2.038 0 3.303 2.212 2.266 3.963z"
    />
  );
}
export default Legend6Nabla;
