import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExportXls(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportXlsIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 1a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-4.828-4.828A2 2 0 0 0 13.172 1zm0 20v-2h12v2zM6 3v8h12V9h-5a1 1 0 0 1-1-1V3zm11.172 4H14V3.828zM11.5 12.6a.4.4 0 0 1 .4.4v3.6h1.6a.4.4 0 0 1 0 .8h-2a.4.4 0 0 1-.4-.4v-4c0-.22.18-.4.4-.4m-4.712.06a.4.4 0 0 1 .55.128l.912 1.458.911-1.458a.4.4 0 0 1 .679.424l-1.118 1.789 1.117 1.787a.4.4 0 1 1-.679.424l-.91-1.457-.91 1.457a.4.4 0 1 1-.679-.424l1.117-1.787-1.118-1.789a.4.4 0 0 1 .128-.551Zm9.101-.06a1.29 1.29 0 0 0-1.289 1.29c0 .525.297 1.006.767 1.24l.908.455c.2.1.325.303.325.526a.49.49 0 0 1-.49.489h-.21a.5.5 0 0 1-.5-.5V16a.4.4 0 0 0-.8 0v.1a1.3 1.3 0 0 0 1.3 1.3h.21a1.29 1.29 0 0 0 1.29-1.29c0-.525-.297-1.006-.767-1.24l-.908-.455a.59.59 0 0 1-.325-.526c0-.27.22-.489.49-.489h.21a.5.5 0 0 1 .5.5v.1a.4.4 0 0 0 .8 0v-.1a1.3 1.3 0 0 0-1.3-1.3h-.21Z"
      clipRule="evenodd"
    />
  );
}
export default ExportXls;
