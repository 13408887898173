import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function FullscreenExit(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'FullscreenExitIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M6 10a4 4 0 0 0 4-4V3a1 1 0 1 0-2 0v3a2 2 0 0 1-2 2H3a1 1 0 1 0 0 2zm4 8a4 4 0 0 0-4-4H3a1 1 0 1 0 0 2h3a2 2 0 0 1 2 2v3a1 1 0 1 0 2 0zm4 0a4 4 0 0 1 4-4h3a1 1 0 1 1 0 2h-3a2 2 0 0 0-2 2v3a1 1 0 1 1-2 0zm0-12a4 4 0 0 0 4 4h3a1 1 0 1 0 0-2h-3a2 2 0 0 1-2-2V3a1 1 0 1 0-2 0z"
    />
  );
}
export default FullscreenExit;
