import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Forward(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ForwardIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M22.645 12.754c.473-.338.473-1.17 0-1.508l-8.56-6.124C13.604 4.78 13 5.2 13 5.876V12c0-.293-.118-.585-.355-.754l-8.56-6.124C3.604 4.78 3 5.2 3 5.876v12.248c0 .676.605 1.097 1.084.754l8.561-6.124c.237-.169.355-.462.355-.754v6.124c0 .676.605 1.097 1.084.754z"
    />
  );
}
export default Forward;
