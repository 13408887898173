import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Language(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LanguageIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12 23.036c5.985 0 11-5.078 11-11.036 0-6.075-4.925-11-11-11S1 5.925 1 12c0 5.977 5.01 11.036 11 11.036m.008-2.017c-.336 0-.62-.243-.838-.474-.363-.384-.736-.992-1.07-1.827A14.4 14.4 0 0 1 9.346 16h5.312c-.19 1.037-.448 1.962-.758 2.736-.334.835-.707 1.444-1.07 1.827-.216.229-.492.457-.822.457ZM9.082 14a23.7 23.7 0 0 1 .003-4h5.829a23.7 23.7 0 0 1 .006 4zm7.606 2h3.377a9.03 9.03 0 0 1-4.7 4.35q.21-.416.392-.871c.4-1.002.717-2.182.93-3.479Zm4.089-2h-3.851a26 26 0 0 0-.006-4h3.857a9 9 0 0 1 0 4M7.076 14H3.223a9 9 0 0 1 0-4h3.855a26 26 0 0 0-.002 4m-3.14 2h3.38c.213 1.29.528 2.463.927 3.46q.187.468.405.895A9.03 9.03 0 0 1 3.936 16m5.416-8c.188-1.015.444-1.922.748-2.682.334-.835.707-1.443 1.07-1.827.36-.381.65-.473.838-.473.189 0 .462.11.823.491.362.384.735.992 1.07 1.827.301.756.556 1.656.744 2.664zm7.324 0c-.213-1.268-.526-2.423-.919-3.406a11 11 0 0 0-.44-.963A9.03 9.03 0 0 1 20.065 8h-3.388ZM8.243 4.576c-.395.988-.708 2.149-.921 3.424H3.936A9.03 9.03 0 0 1 8.67 3.636q-.231.446-.428.94Z"
    />
  );
}
export default Language;
