import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function SpeechBubble(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'SpeechBubbleIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M6 7a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2z" />
      <path
        fillRule="evenodd"
        d="M2 6a4 4 0 0 1 4-4h13a3 3 0 0 1 3 3v8a4 4 0 0 1-4 4h-1v3.297c0 1.857-2.31 2.711-3.518 1.301L9.54 17H6a4 4 0 0 1-4-4zm4-2a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h3.54a2 2 0 0 1 1.519.698L15 20.297V17a2 2 0 0 1 2-2h1a2 2 0 0 0 2-2V5a1 1 0 0 0-1-1z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default SpeechBubble;
