import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ZoomIn(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ZoomInIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M10.5 7a1 1 0 1 0-2 0v1.5H7a1 1 0 0 0 0 2h1.5V12a1 1 0 1 0 2 0v-1.5H12a1 1 0 1 0 0-2h-1.5z" />
      <path
        fillRule="evenodd"
        d="M15.463 14.05a7.5 7.5 0 1 0-1.414 1.414l6.244 6.243a1 1 0 0 0 1.414-1.414l-6.244-6.244ZM9.5 15a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11"
        clipRule="evenodd"
      />
    </g>
  );
}
export default ZoomIn;
