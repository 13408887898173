import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function AudioDescriptionOn(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'AudioDescriptionOnIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M19.35 4.24a1 1 0 0 1 1.41.11C22.169 5.997 23 8.88 23 12s-.831 6.003-2.24 7.65a1 1 0 0 1-1.52-1.3C20.222 17.202 21 14.862 21 12s-.778-5.202-1.76-6.35a1 1 0 0 1 .11-1.41" />
      <path d="M15.264 5.324a1 1 0 0 1 1.412-.06C18.136 6.603 19 8.96 19 11.5s-.863 4.896-2.324 6.237a1 1 0 1 1-1.352-1.474C16.254 15.41 17 13.661 17 11.5s-.745-3.909-1.676-4.763a1 1 0 0 1-.06-1.413" />
      <path
        fillRule="evenodd"
        d="M6.961 14.725a1 1 0 0 1-1.923.55L4.96 15H3.04l-.078.274a1 1 0 0 1-1.924-.55l2-6.999a1 1 0 0 1 1.924 0zM4 11.64 4.389 13H3.61zm5.903 4.345.097-.996-.097.995h.005l.006.002h.016l.05.005.163.007c.135.004.321.004.543-.01a5.7 5.7 0 0 0 1.665-.358c.628-.244 1.3-.658 1.815-1.355.52-.703.825-1.62.834-2.767.009-1.15-.286-2.072-.806-2.78-.514-.701-1.191-1.116-1.822-1.36a5.7 5.7 0 0 0-1.679-.355 6 6 0 0 0-.762 0l-.016.001h-.006l-.003.001c-.001 0-.002 0 .094.996l-.095-.996A1 1 0 0 0 9 8.011v6.978a1 1 0 0 0 .903.995ZM11 13.938V9.06c.21.036.434.091.651.175.373.144.698.36.93.677.228.31.425.793.419 1.58-.006.791-.21 1.28-.442 1.595-.238.321-.564.537-.932.68-.21.082-.424.136-.626.172Z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default AudioDescriptionOn;
