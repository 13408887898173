import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowDropLeft(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ArrowDropLeftIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M13.166 7.321c.677-.686 1.834-.2 1.834.771v7.816c0 .97-1.157 1.457-1.834.77l-3.851-3.907a1.1 1.1 0 0 1 0-1.542z"
    />
  );
}
export default ArrowDropLeft;
