import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function StarFilled(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'StarFilledIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M11.1 2.059a1.004 1.004 0 0 1 1.8 0l2.622 5.307c.146.296.428.5.755.548l5.863.851c.823.12 1.151 1.13.556 1.71l-4.243 4.13a1 1 0 0 0-.288.888l1.002 5.833c.14.819-.72 1.443-1.456 1.057l-5.244-2.755a1 1 0 0 0-.934 0L6.29 22.383a1.003 1.003 0 0 1-1.455-1.057l1.001-5.833a1 1 0 0 0-.288-.887l-4.243-4.131a1.002 1.002 0 0 1 .556-1.71l5.863-.85a1 1 0 0 0 .755-.55z"
    />
  );
}
export default StarFilled;
