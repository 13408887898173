import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function BookmarkAdd(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'BookmarkAddIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M12 6a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2H9a1 1 0 1 1 0-2h2V7a1 1 0 0 1 1-1" />
      <path
        fillRule="evenodd"
        d="M18.514 22.87 12 19.247 5.486 22.87A1 1 0 0 1 4 21.997V4a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v17.997a1 1 0 0 1-1.486.873M6 20.297l5.028-2.797a2 2 0 0 1 1.944 0L18 20.296V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v16.296Z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default BookmarkAdd;
