import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function MoodHappy(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'MoodHappyIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M8.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M17 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-8.25 4.84a1 1 0 1 0-1.5 1.32C8.493 17.076 10.138 18 12 18c1.861 0 3.506-.925 4.75-2.34a1 1 0 0 0-1.5-1.32C14.288 15.43 13.155 16 12 16c-1.156 0-2.289-.57-3.25-1.66" />
      <path
        fillRule="evenodd"
        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11M3 12a9 9 0 1 0 18 0 9 9 0 0 0-18 0"
        clipRule="evenodd"
      />
    </g>
  );
}
export default MoodHappy;
