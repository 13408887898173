import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Print(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PrintIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M8 2a2 2 0 0 0-2 2v2H5a3 3 0 0 0-3 3v6a1 1 0 1 0 2 0V9a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v6a1 1 0 1 0 2 0V9a3 3 0 0 0-3-3h-1V4a2 2 0 0 0-2-2zm8 4V4H8v2z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 14a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm1 2v4h8v-4z"
        clipRule="evenodd"
      />
      <path d="M17 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
    </g>
  );
}
export default Print;
