import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Copy(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CopyIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M5 1a2 2 0 0 0-2 2v13a1 1 0 1 0 2 0V3h9a1 1 0 1 0 0-2z" />
      <path
        fillRule="evenodd"
        d="M7 21V7a2 2 0 0 1 2-2h5.172a2 2 0 0 1 1.414.586l4.828 4.828A2 2 0 0 1 21 11.828V21a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2m8-10h3.172L15 7.828zm-2 1V7H9v14h10v-8h-5a1 1 0 0 1-1-1"
        clipRule="evenodd"
      />
    </g>
  );
}
export default Copy;
