import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Adult(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'AdultIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 7a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2M7.821 9.212A2 2 0 0 1 9.66 8h4.68a2 2 0 0 1 1.84 1.212l2.74 6.394a1 1 0 1 1-1.839.787L15.5 12.704V22a1 1 0 1 1-2 0v-5h-3v5a1 1 0 1 1-2 0v-9.295l-1.58 3.688a1 1 0 1 1-1.839-.787zM10.5 15h3v-5h-3z"
      clipRule="evenodd"
    />
  );
}
export default Adult;
