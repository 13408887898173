import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Flickr(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'FlickrIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.287 14.92c-1.636 0-2.962-1.307-2.962-2.92q0-.2.028-.392c.194-1.427 1.433-2.528 2.934-2.528 1.192 0 2.22.697 2.69 1.7.173.372.27.785.27 1.22 0 1.613-1.325 2.92-2.96 2.92m7.427 0c-1.635 0-2.96-1.307-2.96-2.92 0-.426.094-.83.26-1.197a2.96 2.96 0 0 1 2.7-1.723c1.555 0 2.83 1.184 2.95 2.689q.01.114.01.231c0 1.613-1.325 2.92-2.96 2.92"
      clipRule="evenodd"
    />
  );
}
export default Flickr;
