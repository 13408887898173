import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Backwards5Sec(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'Backwards5SecIcon',
      },
      props
    ),
    <g fill="#1F1F1F">
      <path d="m4.293 14.293-2.829-2.829a1 1 0 1 1 1.414-1.414l1.127 1.127A9.5 9.5 0 0 1 13.5 2a9.5 9.5 0 0 1 0 19 1 1 0 1 1 0-2 7.5 7.5 0 1 0-7.493-7.835l1.114-1.115a1 1 0 0 1 1.415 1.415l-2.829 2.828a1 1 0 0 1-1.414 0" />
      <path d="M15 7h-4v3.948c0 .581.47 1.052 1.052 1.052H13a1 1 0 1 1 0 2h-1a1 1 0 1 0 0 2h1a3 3 0 1 0 0-6V9h2a1 1 0 1 0 0-2" />
    </g>
  );
}
export default Backwards5Sec;
