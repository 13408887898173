import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowCollapse(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ArrowCollapseIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="m14.994 7.657 5.365-5.365a1.003 1.003 0 0 1 1.414 0 1.003 1.003 0 0 1 0 1.414L16.408 9.07l3.08.007a.996.996 0 1 1 0 1.994h-5.491a.996.996 0 0 1-.997-.997v-5.49a.996.996 0 1 1 1.994 0zm-5.923 8.758-5.365 5.366a1.003 1.003 0 0 1-1.414 0 1.003 1.003 0 0 1 0-1.415l5.365-5.365-3.08-.007a.996.996 0 1 1 0-1.994h5.491c.552 0 .997.445.997.997v5.491a.996.996 0 1 1-1.994 0z"
    />
  );
}
export default ArrowCollapse;
