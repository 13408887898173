import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function AedPadsCorrect(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'AedPadsCorrectIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M21 8a4 4 0 0 1-2.977 3.868c.128 2.28.757 3.54 1.355 4.223.677.774 1.399.909 1.622.909a1 1 0 1 1 0 2c-.777 0-2.055-.366-3.128-1.592-.976-1.116-1.717-2.867-1.85-5.529A4 4 0 0 1 13 8V5a4 4 0 0 1 8 0zm-2 0V5a2 2 0 1 0-4 0v3a2 2 0 1 0 4 0"
        clipRule="evenodd"
      />
      <path d="M4 9a1 1 0 0 1 1 1v3a2 2 0 1 0 4 0v-3a1 1 0 1 1 2 0v3a4 4 0 0 1-2.886 3.843c.204.711.676 1.303 1.382 1.818.998.728 2.41 1.252 3.979 1.614C16.602 20.998 20 21 21 21a1 1 0 1 1 0 2h-.01c-1.014 0-4.604 0-7.965-.776-1.68-.388-3.394-.989-4.708-1.948-1.125-.82-1.988-1.933-2.241-3.383A4 4 0 0 1 3 13v-3a1 1 0 0 1 1-1m6.707-6.293a1 1 0 0 0-1.414-1.414L5.998 4.586l-1.29-1.293a1 1 0 1 0-1.416 1.414l1.644 1.646a1.5 1.5 0 0 0 2.122 0z" />
    </g>
  );
}
export default AedPadsCorrect;
