import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Fullscreen(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'FullscreenIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M6 2a4 4 0 0 0-4 4v3a1 1 0 0 0 2 0V6a2 2 0 0 1 2-2h3a1 1 0 0 0 0-2zM2 18a4 4 0 0 0 4 4h3a1 1 0 1 0 0-2H6a2 2 0 0 1-2-2v-3a1 1 0 1 0-2 0zm20 0a4 4 0 0 1-4 4h-3a1 1 0 1 1 0-2h3a2 2 0 0 0 2-2v-3a1 1 0 1 1 2 0zm0-12a4 4 0 0 0-4-4h-3a1 1 0 1 0 0 2h3a2 2 0 0 1 2 2v3a1 1 0 1 0 2 0z"
    />
  );
}
export default Fullscreen;
