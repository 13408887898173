import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function LinkedIn(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LinkedInIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12.766 18.756V13.47c0-.562.281-.97.716-1.303.894-.639 2.043-.23 2.324.817.077.332.128.716.128 1.048v4.699h3.014V13.47c0-.409-.051-.843-.128-1.252-.256-1.226-.92-2.145-2.146-2.554a3.8 3.8 0 0 0-1.507-.153c-.868.05-1.583.408-2.12 1.072l-.23.307h-.05V9.714H9.751v9.017c.996.025 1.993.025 3.014.025ZM5.052 9.74v9.016h2.989V9.74zm1.533-4.368c-.74 0-1.354.357-1.584.945-.408.996.205 2.018 1.277 2.145.894.103 1.61-.255 1.89-.945.41-1.073-.357-2.145-1.583-2.145"
    />
  );
}
export default LinkedIn;
