import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function MapPoint(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'MapPointIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M15 7.997a3 3 0 1 0-5.999.001 3 3 0 0 0 5.999 0Zm-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
      <path d="M13.153 21.842c1.218-1.888 6.01-9.295 6.01-13.683A7.16 7.16 0 0 0 12 1C8.044 1 4.838 4.613 4.838 8.567c0 3.661 4.817 11.387 5.972 13.275l.53.811a.778.778 0 0 0 1.303-.01c0-.006.51-.801.51-.801M9.84 16.308c-.792-1.445-1.562-2.988-2.13-4.411-.588-1.476-.872-2.617-.872-3.33C6.838 5.572 9.289 3 12 3a5.16 5.16 0 0 1 5.162 5.16c0 .835-.298 2.063-.884 3.578-.57 1.472-1.341 3.044-2.134 4.508a73 73 0 0 1-2.163 3.709 75 75 0 0 1-2.141-3.646Z" />
    </g>
  );
}
export default MapPoint;
