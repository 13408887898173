import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Share(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ShareIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M20.465 7a4 4 0 1 0-7.315-.917L7.792 9.176a4 4 0 1 0 .105 5.626l5.273 3.044A4.002 4.002 0 0 0 20.465 21a4 4 0 0 0-6.257-4.866l-5.337-3.08a4 4 0 0 0-.04-2.169l5.323-3.074A4 4 0 0 0 20.464 7Zm-1.732-1a2 2 0 1 1-3.464-2 2 2 0 0 1 3.464 2m-12 7.042a2 2 0 1 1-3.464-2 2 2 0 0 1 3.464 2M16 20.732a2 2 0 1 1 2-3.463 2 2 0 0 1-2 3.464Z"
    />
  );
}
export default Share;
