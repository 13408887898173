import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Asterisk(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'AsteriskIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12 5a1 1 0 0 1 1 1v4.268l3.696-2.134a1 1 0 0 1 1 1.732L14 12l3.696 2.134a1 1 0 0 1-1 1.732L13 13.732V18a1 1 0 1 1-2 0v-4.268l-3.696 2.134a1 1 0 1 1-1-1.732L10 12 6.304 9.866a1 1 0 1 1 1-1.732L11 10.268V6a1 1 0 0 1 1-1"
    />
  );
}
export default Asterisk;
