import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function PointDown(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PointDownIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M9 20v-2.265a3 3 0 0 0 3.484-1.146 2.997 2.997 0 0 0 4.045-.974A3 3 0 0 0 21 13v-2q0-.111-.008-.219H21V8a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v12a3 3 0 1 0 6 0M19 8.17a3 3 0 0 0-3.529 1.214A3 3 0 0 0 14 9.001c-.53 0-1.028.138-1.46.379a3.4 3.4 0 0 0-.593-.795l-1.898-1.898A1 1 0 0 0 8.635 8.1l1.898 1.898A1.417 1.417 0 0 1 9 12.314v-.011h-.027a1.4 1.4 0 0 1-.445-.301L5.335 8.808A1.14 1.14 0 0 1 5 8a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5zM13 14v-2a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0m4-1v-2a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0m-10 .302V20a1 1 0 1 1-2 0v-8.698zm2 1.074a3.43 3.43 0 0 0 2-.29v.82a1 1 0 1 1-2 0z"
    />
  );
}
export default PointDown;
