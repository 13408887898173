import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExportExl(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportExlIcon' },
      props
    ),
    <path
      fill="#1F1F1F"
      fillRule="evenodd"
      d="M4 3a2 2 0 0 1 2-2h7.172a2 2 0 0 1 1.414.586l4.828 4.828A2 2 0 0 1 20 7.828V21a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2 16v2h12v-2zm12-8H6V3h6v5a1 1 0 0 0 1 1h5zm-4-4h3.172L14 3.828zm-7 5.6a.4.4 0 0 0-.4.4v4c0 .22.18.4.4.4h2a.4.4 0 0 0 0-.8H7.4v-1.2h1.1a.4.4 0 0 0 0-.8H7.4v-1.2H9a.4.4 0 0 0 0-.8zm8.9.4a.4.4 0 0 0-.8 0v4c0 .22.18.4.4.4h2a.4.4 0 0 0 0-.8h-1.6zm-4.561-.212a.4.4 0 0 0-.678.424l1.117 1.789-1.117 1.787a.4.4 0 0 0 .678.424l.911-1.457.91 1.457a.4.4 0 1 0 .679-.424l-1.117-1.787 1.117-1.789a.4.4 0 1 0-.678-.424l-.911 1.458z"
      clipRule="evenodd"
    />
  );
}
export default ExportExl;
