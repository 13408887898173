import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ChevronUp(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ChevronUpIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.708 15.197a1 1 0 0 1-1.414.002L12 8.921 5.706 15.2a1 1 0 0 1-1.412-1.416l7-6.982a1 1 0 0 1 1.412 0l7 6.982a1 1 0 0 1 .002 1.415Z"
      clipRule="evenodd"
    />
  );
}
export default ChevronUp;
