import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Home(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'HomeIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m20 12.012.293.293a1 1 0 0 0 1.414-1.415l-8.313-8.313a1.97 1.97 0 0 0-2.788 0L2.293 10.89a1 1 0 1 0 1.414 1.415L4 12.012V20a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-2-2V20h-3v-6a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v6H6v-9.988l6-6zM11 14h2v6h-2z"
    />
  );
}
export default Home;
