import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function DecisionFlow(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'DecisionFlowIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M21 14.707a1 1 0 0 0-1.414-1.414l-1.293 1.293L17 13.293a1 1 0 0 0-1.414 1.414L16.879 16l-1.293 1.293A1 1 0 0 0 17 18.707l1.293-1.293 1.293 1.293A1 1 0 0 0 21 17.293L19.707 16z" />
      <path
        fillRule="evenodd"
        d="M22.707 5.293a1 1 0 0 1 0 1.415l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.415L18 8.586l3.293-3.293a1 1 0 0 1 1.414 0"
        clipRule="evenodd"
      />
      <path d="M9.293 5.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-2 2a1 1 0 0 1-1.414-1.414L9.586 9h-1.93a3 3 0 0 0-2.12.879L3.413 12l2.122 2.122a3 3 0 0 0 2.12.878h1.93l-.293-.293a1 1 0 0 1 1.414-1.414l2 2a1 1 0 0 1 0 1.414l-2 2a1 1 0 0 1-1.414-1.414L9.586 17h-1.93a5 5 0 0 1-3.535-1.464l-2.828-2.829a1 1 0 0 1 0-1.414L4.12 8.464A5 5 0 0 1 7.657 7h1.928l-.292-.293a1 1 0 0 1 0-1.414" />
    </g>
  );
}
export default DecisionFlow;
