import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowLineUp(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ArrowLineUpIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M13.005 19V7.413l2.88 2.88a.996.996 0 1 0 1.41-1.41l-4.59-4.59a.996.996 0 0 0-1.41 0l-4.59 4.59a.996.996 0 1 0 1.41 1.41l2.89-2.88V19c0 .55.45 1 1 1s1-.45 1-1"
    />
  );
}
export default ArrowLineUp;
