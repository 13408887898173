import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function OpenNewWindow(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'OpenNewWindowIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M20 10a1 1 0 0 1-1-1V6.414l-6.228 6.229c-.417.416-1.07.437-1.461.047-.39-.391-.37-1.045.046-1.461L17.586 5H15a1 1 0 1 1 0-2h5a.996.996 0 0 1 1 1v5a1 1 0 0 1-1 1" />
      <path d="M5 6a1 1 0 0 1 1-1h4a1 1 0 1 0 0-2H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-4a1 1 0 1 0-2 0v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1z" />
    </g>
  );
}
export default OpenNewWindow;
