import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Document(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'DocumentIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M9 15a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2zm-1-3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1" />
      <path d="M4 3v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-4.828-4.828A2 2 0 0 0 13.172 1H6a2 2 0 0 0-2 2m13.172 4H14V3.828zM12 3v5a1 1 0 0 0 1 1h5v12H6V3z" />
    </g>
  );
}
export default Document;
