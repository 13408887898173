import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function LikeOn(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LikeOnIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.74 5.343a5.806 5.806 0 0 1 8.21 0l.07.07.07-.07a5.806 5.806 0 0 1 8.21 8.21l-7.564 7.565a1 1 0 0 1-.725.293 1 1 0 0 1-.707-.293l-7.565-7.564a5.806 5.806 0 0 1 0-8.211Z"
      clipRule="evenodd"
    />
  );
}
export default LikeOn;
