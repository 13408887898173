import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExportJpg(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportJpgIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M11.5 14.6a.4.4 0 0 0-.1.013V13.4h.8a.4.4 0 0 1 .4.4v.4a.4.4 0 0 1-.4.4z" />
      <path
        fillRule="evenodd"
        d="M6 1a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-4.828-4.828A2 2 0 0 0 13.172 1zm0 20v-2h12v2zM6 3v8h12V9h-5a1 1 0 0 1-1-1V3zm11.172 4H14V3.828zM6.6 13c0-.22.18-.4.4-.4h1.5a.4.4 0 0 1 .4.4v2.836a1.6 1.6 0 0 1-1.551 1.6l-.337.01a.4.4 0 0 1-.024-.8l.336-.01a.8.8 0 0 0 .776-.8V13.4H7a.4.4 0 0 1-.4-.4m9.4.4a.6.6 0 0 0-.6.6v2a.6.6 0 0 0 .6.6h.2a.4.4 0 0 0 .4-.4v-.3h-.1a.4.4 0 0 1 0-.8h.5a.4.4 0 0 1 .4.4v.7a1.2 1.2 0 0 1-1.2 1.2H16a1.4 1.4 0 0 1-1.4-1.4v-2a1.4 1.4 0 0 1 1.4-1.4h.2a1.2 1.2 0 0 1 1.2 1.2v.1a.4.4 0 0 1-.8 0v-.1a.4.4 0 0 0-.4-.4zm-5-.8a.4.4 0 0 0-.4.4v4a.4.4 0 0 0 .8 0v-1.613a.4.4 0 0 0 .1.013h.7a1.2 1.2 0 0 0 1.2-1.2v-.4a1.2 1.2 0 0 0-1.2-1.2z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default ExportJpg;
