import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Folder(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'FolderIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 5a2 2 0 0 1 2-2h6.65a2 2 0 0 1 1.249.438L12.85 5H21a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2zm8.65 0H3v13h18V7h-8.15a2 2 0 0 1-1.249-.438z"
      clipRule="evenodd"
    />
  );
}
export default Folder;
