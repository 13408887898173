import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Edit(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'EditIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M21.122 2.878a2.997 2.997 0 0 0-4.238 0L6.585 13.177A2 2 0 0 0 6 14.59v1.412C6 17.106 6.894 18 7.998 18H9.41c.53 0 1.038-.21 1.413-.585l10.3-10.3a2.997 2.997 0 0 0 0-4.237ZM19.71 4.29a1 1 0 0 1 0 1.413l-1.138 1.138-1.413-1.413 1.138-1.138a1 1 0 0 1 1.413 0M8.579 14.008l7.167-7.167 1.413 1.413-7.168 7.167L8.58 14.01ZM3 20a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2z"
    />
  );
}
export default Edit;
