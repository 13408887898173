import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function SkipForward(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'SkipForwardIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M15.198 12.646c.201-.145.302-.395.302-.646 0-.25-.1-.502-.302-.646l-7.276-5.25C7.514 5.812 7 6.172 7 6.752V17.25c0 .58.514.94.922.646l7.276-5.248ZM17.5 6a1 1 0 0 0-1 1v10a1 1 0 1 0 2 0V7a1 1 0 0 0-1-1"
    />
  );
}
export default SkipForward;
