import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CloudCheck(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CloudCheckIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M17.328 7.303a1 1 0 0 1-1.414-.043A4 4 0 0 0 9 10a1 1 0 0 1-1 1 2 2 0 0 0-2 2 1 1 0 0 1-1 1h-.5a1.5 1.5 0 0 0 0 3h14a2.5 2.5 0 0 0 2.444-3.028 1 1 0 1 1 1.956-.42A4.5 4.5 0 0 1 18.529 19H4.5a3.5 3.5 0 0 1-.38-6.98 4.01 4.01 0 0 1 2.946-2.91A6.001 6.001 0 0 1 17.37 5.89a1 1 0 0 1-.043 1.413Z" />
      <path d="M21.672 8.983a1 1 0 0 0-1.408-1.42l-5.492 5.45-1.388-1.374a1 1 0 1 0-1.41 1.418l1.74 1.724a1.5 1.5 0 0 0 2.114.001l5.844-5.8Z" />
    </g>
  );
}
export default CloudCheck;
