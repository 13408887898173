import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExportPdf(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportPdfIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M7.5 14.6a.4.4 0 0 0-.1.013V13.4h.8a.4.4 0 0 1 .4.4v.4a.4.4 0 0 1-.4.4zm3.9 2.036V13.4h.4a.8.8 0 0 1 .8.8v1.627a.8.8 0 0 1-.782.8l-.418.01Z" />
      <path
        fillRule="evenodd"
        d="M4 3a2 2 0 0 1 2-2h7.172a2 2 0 0 1 1.414.586l4.828 4.828A2 2 0 0 1 20 7.828V21a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2 16v2h12v-2zm12-8H6V3h6v5a1 1 0 0 0 1 1h5zm-4-4h3.172L14 3.828zm-7 5.6a.4.4 0 0 0-.4.4v4a.4.4 0 0 0 .8 0v-1.613a.4.4 0 0 0 .1.013h.7a1.2 1.2 0 0 0 1.2-1.2v-.4a1.2 1.2 0 0 0-1.2-1.2zm4 0a.4.4 0 0 0-.4.4v4.046a.4.4 0 0 0 .41.4l.827-.02a1.6 1.6 0 0 0 1.563-1.599V14.2a1.6 1.6 0 0 0-1.6-1.6zm4 0a.4.4 0 0 0-.4.4v4a.4.4 0 0 0 .8 0v-1.6h1.1a.4.4 0 0 0 0-.8h-1.1v-1.2H17a.4.4 0 0 0 0-.8z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default ExportPdf;
