import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Battery6(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'Battery6Icon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 10v4h2v1a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v1zm4 5V9h2v6z"
      clipRule="evenodd"
    />
  );
}
export default Battery6;
