import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowDropDown(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ArrowDropDownIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M7.321 11.834c-.686-.677-.2-1.834.771-1.834h7.816c.97 0 1.457 1.157.77 1.834l-3.907 3.851c-.426.42-1.116.42-1.542 0z"
    />
  );
}
export default ArrowDropDown;
