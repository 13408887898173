import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Video(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'VideoIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="m15.502 12.87-5.08 2.989a1.01 1.01 0 0 1-1.523-.87V9.011c0-.782.85-1.267 1.523-.871l5.08 2.988a1.01 1.01 0 0 1 0 1.742" />
      <path d="M1 7v10a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3m3-1h16a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1" />
    </g>
  );
}
export default Video;
