import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExportCsv(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportCsvIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 3a2 2 0 0 1 2-2h7.172a2 2 0 0 1 1.414.586l4.828 4.828A2 2 0 0 1 20 7.828V21a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm14 6v2H6V3h6v5a1 1 0 0 0 1 1zM6 21v-2h12v2zm8-14h3.172L14 3.828zm.36 5.625a.4.4 0 0 1 .515.235l1.125 3 1.126-3a.4.4 0 0 1 .749.28l-1.5 4a.4.4 0 0 1-.75 0l-1.5-4a.4.4 0 0 1 .235-.515M8 13.4a.6.6 0 0 0-.6.6v2a.6.6 0 0 0 .6.6h.2a.4.4 0 0 0 .4-.4V16a.4.4 0 1 1 .8 0v.2a1.2 1.2 0 0 1-1.2 1.2H8A1.4 1.4 0 0 1 6.6 16v-2A1.4 1.4 0 0 1 8 12.6h.2a1.2 1.2 0 0 1 1.2 1.2v.2a.4.4 0 0 1-.8 0v-.2a.4.4 0 0 0-.4-.4zm2.6.49c0-.713.577-1.29 1.29-1.29h.21a1.3 1.3 0 0 1 1.3 1.3v.1a.4.4 0 0 1-.8 0v-.1a.5.5 0 0 0-.5-.5h-.21a.49.49 0 0 0-.49.49c0 .222.126.426.325.525l.908.454c.47.235.767.716.767 1.242a1.29 1.29 0 0 1-1.29 1.289h-.21a1.3 1.3 0 0 1-1.3-1.3V16a.4.4 0 1 1 .8 0v.1a.5.5 0 0 0 .5.5h.21a.49.49 0 0 0 .49-.49.59.59 0 0 0-.325-.525l-.908-.454a1.39 1.39 0 0 1-.767-1.242Z"
      clipRule="evenodd"
    />
  );
}
export default ExportCsv;
