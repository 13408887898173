import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExportCvs(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportCvsIcon' },
      props
    ),
    <path
      fill="#1F1F1F"
      fillRule="evenodd"
      d="M4 3a2 2 0 0 1 2-2h7.172a2 2 0 0 1 1.414.586l4.828 4.828A2 2 0 0 1 20 7.828V21a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2 16v2h12v-2zm12-8H6V3h6v5a1 1 0 0 0 1 1h5zm-4-4h3.172L14 3.828zm-3.126 5.86a.4.4 0 0 0-.749.28l1.5 4a.4.4 0 0 0 .75 0l1.5-4a.4.4 0 1 0-.75-.28l-1.125 3zM7.4 14a.6.6 0 0 1 .6-.6h.2a.4.4 0 0 1 .4.4v.2a.4.4 0 0 0 .8 0v-.2a1.2 1.2 0 0 0-1.2-1.2H8A1.4 1.4 0 0 0 6.6 14v2A1.4 1.4 0 0 0 8 17.4h.2a1.2 1.2 0 0 0 1.2-1.2V16a.4.4 0 0 0-.8 0v.2a.4.4 0 0 1-.4.4H8a.6.6 0 0 1-.6-.6zm7.2-.11c0-.713.577-1.29 1.29-1.29h.21a1.3 1.3 0 0 1 1.3 1.3v.1a.4.4 0 0 1-.8 0v-.1a.5.5 0 0 0-.5-.5h-.21a.49.49 0 0 0-.49.49c0 .222.126.426.325.525l.908.454c.47.235.767.716.767 1.242a1.29 1.29 0 0 1-1.29 1.289h-.21a1.3 1.3 0 0 1-1.3-1.3V16a.4.4 0 0 1 .8 0v.1a.5.5 0 0 0 .5.5h.21a.49.49 0 0 0 .49-.49.59.59 0 0 0-.325-.525l-.908-.454a1.39 1.39 0 0 1-.767-1.242Z"
      clipRule="evenodd"
    />
  );
}
export default ExportCvs;
