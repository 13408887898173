import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Time(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TimeIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M12 7a1 1 0 0 0-1 1v4.003c0 .275.113.524.293.704l2.828 2.829a1 1 0 1 0 1.415-1.415L13 11.586V8a1 1 0 0 0-1-1" />
      <path d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11m0-2a9 9 0 1 1 0-18 9 9 0 0 1 0 18" />
    </g>
  );
}
export default Time;
