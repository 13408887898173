import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function NotificationNew(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'NotificationNewIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M4.402 5.5c.285.493.688.874 1.152 1.13a6.22 6.22 0 0 0-.857 5.557l2.492 7.632a1 1 0 0 0 1.45.555l11.722-6.767a1 1 0 0 0 .244-1.534L15.24 6.1A6.22 6.22 0 0 0 10 4.064 3 3 0 1 0 4.402 5.5M7.5 4.867l-.013.007a1 1 0 1 1 .027-.015zm-.902 6.699A4.22 4.22 0 0 1 8.48 6.609l.02-.01.018-.011a4.22 4.22 0 0 1 5.234.847l4.54 5.057-9.585 5.534zm7.67 9.387c-.013-.552-.656-.729-1.134-.453l-1.732 1c-.478.276-.647.921-.175 1.208a2 2 0 0 0 3.04-1.755Z"
    />
  );
}
export default NotificationNew;
