import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExportDoc(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportDocIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M7.4 13.4v3.237l.418-.01a.8.8 0 0 0 .782-.8V14.2a.8.8 0 0 0-.8-.8zm4.6 0a.6.6 0 0 0-.6.6v2.023a.6.6 0 1 0 1.2 0V14a.6.6 0 0 0-.6-.6" />
      <path
        fillRule="evenodd"
        d="M6 1a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-4.828-4.828A2 2 0 0 0 13.172 1zm0 20v-2h12v2zM6 3v8h12V9h-5a1 1 0 0 1-1-1V3zm11.172 4H14V3.828zM6.6 13c0-.22.18-.4.4-.4h.8a1.6 1.6 0 0 1 1.6 1.6v1.627a1.6 1.6 0 0 1-1.563 1.6l-.828.019a.4.4 0 0 1-.409-.4zm4 1a1.4 1.4 0 1 1 2.8 0v2.023a1.4 1.4 0 0 1-2.8 0zm5.4-.6a.6.6 0 0 0-.6.6v2a.6.6 0 0 0 .6.6h.2a.4.4 0 0 0 .4-.4V16a.4.4 0 0 1 .8 0v.2a1.2 1.2 0 0 1-1.2 1.2H16a1.4 1.4 0 0 1-1.4-1.4v-2a1.4 1.4 0 0 1 1.4-1.4h.2a1.2 1.2 0 0 1 1.2 1.2v.2a.4.4 0 0 1-.8 0v-.2a.4.4 0 0 0-.4-.4z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default ExportDoc;
