import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ShoppingCart(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ShoppingCartIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M7.45 13h7.45l1.1 2H5c-.55 0-1 .45-1 1s.45 1 1 1h11c1.52 0 2.48-1.63 1.75-2.97l-1.35-2.44L20 4h1c.55 0 1-.45 1-1s-.45-1-1-1h-1.64c-.38 0-.74.22-.9.57L17.783 4H3a1 1 0 0 0-.865 1.504L5.7 11.97c.34.62 1 1.03 1.75 1.03M4.685 6h12.152l-2.367 5H7.45zm.869 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m9.946 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
    />
  );
}
export default ShoppingCart;
