import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function HeartShock(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'HeartShockIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M11.911 4.7a5.806 5.806 0 0 0-8.21 8.211l7.564 7.565a1 1 0 0 0 1.414-1.415l-7.564-7.564a3.806 3.806 0 0 1 5.382-5.382l.768.767a1 1 0 0 0 .743.293 1 1 0 0 0 .743-.293l.767-.767a3.806 3.806 0 0 1 6.193 4.184c-.654 1.535 1.203 2.21 1.798.881a5.808 5.808 0 0 0-9.405-6.48l-.096.097-.097-.096Z" />
      <path d="M16.508 9.564a1 1 0 1 0-1.664-1.11l-2.667 4a1 1 0 0 0 .832 1.555h2.132l-1.63 2.446a1 1 0 1 0 1.664 1.109l2.666-4a1 1 0 0 0-.832-1.555h-2.131z" />
    </g>
  );
}
export default HeartShock;
