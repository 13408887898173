import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function YouTube(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'YouTubeIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.807 5.283a2.55 2.55 0 0 1 1.773 1.809C22 8.682 22 12 22 12s0 3.318-.41 4.908c-.238.887-.908 1.578-1.772 1.808-1.568.427-7.818.427-7.818.427s-6.25 0-7.807-.427a2.55 2.55 0 0 1-1.773-1.808C2 15.318 2 12 2 12s0-3.318.41-4.908c.238-.887.908-1.578 1.772-1.809C5.75 4.857 12 4.857 12 4.857s6.25 0 7.807.426M15.182 12l-5.227 3.019V8.98l5.227 3.018Z"
      clipRule="evenodd"
    />
  );
}
export default YouTube;
