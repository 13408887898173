import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CheckboxSemi(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CheckboxSemiIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 1.996a4 4 0 0 0-4 4V18a4 4 0 0 0 4 4h12.004a4 4 0 0 0 4-4V5.996a4 4 0 0 0-4-4zM8 11a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2z"
      clipRule="evenodd"
    />
  );
}
export default CheckboxSemi;
