import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Refresh(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'RefreshIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m17.985 11.57-1.278-1.277a1 1 0 1 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414l-1.303 1.303A8 8 0 1 0 12 20a1 1 0 1 0 0-2 6 6 0 1 1 5.985-6.43"
    />
  );
}
export default Refresh;
