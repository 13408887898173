import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Legend3Star(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'Legend3StarIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M11.625 4.511a1.005 1.005 0 0 1 1.75 0l2.005 3.615c.143.259.397.44.691.494l4.115.758c.775.143 1.083 1.074.54 1.638l-2.875 2.992a.97.97 0 0 0-.264.8l.538 4.083c.102.77-.703 1.345-1.416 1.013l-3.781-1.766a1.01 1.01 0 0 0-.856 0l-3.78 1.765c-.714.333-1.519-.242-1.417-1.012l.538-4.083a.97.97 0 0 0-.264-.8l-2.876-2.992c-.542-.564-.234-1.495.541-1.638L8.93 8.62a1 1 0 0 0 .692-.494z"
    />
  );
}
export default Legend3Star;
