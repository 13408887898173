import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function EventLog(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'EventLogIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M1 5a4 4 0 0 1 4-4h11a4 4 0 0 1 4 4v1a1 1 0 1 1-2 0V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3a1 1 0 1 1 0 2H5a4 4 0 0 1-4-4z" />
      <path d="M16 13a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1" />
      <path d="M16 23a7 7 0 1 0 0-14 7 7 0 0 0 0 14m0-2a5 5 0 1 1 0-10 5 5 0 0 1 0 10M6.009 5a1 1 0 0 0 0 2H15a1 1 0 1 0 0-2zM5 10a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h.009a1 1 0 1 0 0-2z" />
    </g>
  );
}
export default EventLog;
