import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function VolumeUp(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'VolumeUpIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M18.555 3.168a1 1 0 0 0-1.11 1.664C19.535 6.227 21 8.887 21 12s-1.465 5.773-3.555 7.169a1 1 0 0 0 1.11 1.663C21.248 19.034 23 15.723 23 12s-1.752-7.034-4.445-8.832M5.167 16.167l2.419 2.419c1.26 1.26 3.414.367 3.414-1.414V6.829c0-1.782-2.154-2.675-3.414-1.415l-2.42 2.42H3a2 2 0 0 0-2 2v4.333a2 2 0 0 0 2 2zm.828-6.334L9 6.83v10.343l-3.005-3.005H3V9.833z" />
      <path d="M14.168 7.445a1 1 0 0 1 1.387-.277C17.052 8.168 18 9.986 18 12s-.948 3.832-2.445 4.832a1 1 0 0 1-1.11-1.664C15.339 14.572 16 13.405 16 12c0-1.404-.66-2.571-1.555-3.168a1 1 0 0 1-.277-1.387" />
    </g>
  );
}
export default VolumeUp;
