import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Save(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'SaveIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M5 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V6.828a2 2 0 0 0-.586-1.414l-2.828-2.828A2 2 0 0 0 17.172 2zm13 18v-7a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v7H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h2.5v2a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V4h.672L20 6.828V19a1 1 0 0 1-1 1zm-2 0H8v-7h8zM9.5 6V4h5v2z"
    />
  );
}
export default Save;
