import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function MoodVeryHappy(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'MoodVeryHappyIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M8.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M17 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
      <path
        fillRule="evenodd"
        d="M12 19c2.245 0 4.338-1.62 6.1-4.415.421-.668-.13-1.49-.917-1.428-3.79.296-6.522.295-10.37-.003-.785-.06-1.335.76-.915 1.428C7.661 17.379 9.755 19 12 19m-3.073-3.704c2.134.109 4.03.11 6.144.003C13.934 16.535 12.873 17 12 17c-.875 0-1.936-.466-3.074-1.704Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11M3 12a9 9 0 1 0 18 0 9 9 0 0 0-18 0"
        clipRule="evenodd"
      />
    </g>
  );
}
export default MoodVeryHappy;
