import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CheckboxOn(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CheckboxOnIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 5.996a4 4 0 0 1 4-4h12.004a4 4 0 0 1 4 4V18a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4zm4.3 5.89a1 1 0 0 1 1.415 0L10 14.173l6.295-6.295a1 1 0 1 1 1.414 1.414l-7.002 7.002a.997.997 0 0 1-1.414 0L6.3 13.3a1 1 0 0 1 0-1.414"
      clipRule="evenodd"
    />
  );
}
export default CheckboxOn;
