import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Metronome(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'MetronomeIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.076 1A2 2 0 0 0 7.12 2.583l-3.84 18A2 2 0 0 0 5.236 23h13.52a2 2 0 0 0 1.953-2.43l-1.282-5.83a1 1 0 1 0-1.953.43L18.756 21H16.9a5.01 5.01 0 0 0-3.19-3.7l6.11-8.726a1 1 0 1 0-1.64-1.147l-6.72 9.602A5 5 0 0 0 7.1 21H5.236l3.84-18h5.851l.548 2.516a1 1 0 1 0 1.955-.426l-.548-2.516A2 2 0 0 0 14.927 1zm.095 20h5.658a3.001 3.001 0 0 0-5.658 0"
      clipRule="evenodd"
    />
  );
}
export default Metronome;
