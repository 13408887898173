import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function StarOutlined(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'StarOutlinedIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m12 4.753 1.729 3.499a3 3 0 0 0 2.26 1.641l3.861.56-2.792 2.72a3 3 0 0 0-.864 2.658l.66 3.842-3.457-1.815a3 3 0 0 0-2.794 0l-3.456 1.815.66-3.842a3 3 0 0 0-.865-2.658l-2.793-2.72 3.861-.56a3 3 0 0 0 2.261-1.641zm.9-2.694a1.004 1.004 0 0 0-1.8 0L8.478 7.366c-.146.296-.428.5-.755.548l-5.863.851a1.002 1.002 0 0 0-.556 1.71l4.243 4.13c.236.231.344.563.288.888l-1.001 5.833a1.003 1.003 0 0 0 1.455 1.057l5.244-2.755c.292-.153.642-.153.934 0l5.244 2.755a1.003 1.003 0 0 0 1.456-1.057l-1.002-5.833a1 1 0 0 1 .288-.887l4.243-4.131c.595-.58.267-1.59-.556-1.71l-5.863-.85a1 1 0 0 1-.755-.55z"
    />
  );
}
export default StarOutlined;
