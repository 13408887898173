import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Upload(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'UploadIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M7.758 5.828a1 1 0 1 0 1.414 1.415L11 5.415V13a1 1 0 1 0 2 0V5.414l1.829 1.829a1 1 0 1 0 1.414-1.415l-3.535-3.535a.997.997 0 0 0-1.434.02L7.758 5.827Z" />
      <path d="M3 14a1 1 0 0 1 1 1v3a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-3a1 1 0 0 1 2 0v3a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-3a1 1 0 0 1 1-1" />
    </g>
  );
}
export default Upload;
