import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Child(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ChildIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-2.65 3.373A2 2 0 0 1 10.514 10h2.971a2 2 0 0 1 1.164.373l3.933 2.814a1 1 0 0 1-1.164 1.626L15 13.083V21a1 1 0 0 1-2 0v-3h-2v3a1 1 0 0 1-2 0v-7.917l-2.418 1.73a1 1 0 0 1-1.164-1.626l3.932-2.813ZM11 16h2v-4h-2z"
      clipRule="evenodd"
    />
  );
}
export default Child;
