import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function AedPads(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'AedPadsIcon' },
      props
    ),
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M18.023 11.868A4 4 0 0 0 21 8V5a4 4 0 0 0-8 0v3a4 4 0 0 0 3.021 3.88c.134 2.66.875 4.412 1.851 5.528 1.073 1.226 2.351 1.591 3.128 1.591a1 1 0 1 0 0-2c-.223 0-.945-.133-1.622-.908-.598-.683-1.227-1.942-1.355-4.223M19 5v3a2 2 0 1 1-4 0V5a2 2 0 1 1 4 0" />
      <path d="M11 13a4 4 0 0 1-2.886 3.843c.204.711.676 1.303 1.382 1.818.998.728 2.41 1.252 3.979 1.614C16.602 20.998 20 21 21 21a1 1 0 1 1 0 2h-.01c-1.014 0-4.604 0-7.965-.776-1.68-.388-3.394-.989-4.708-1.948-1.125-.82-1.988-1.933-2.241-3.383A4 4 0 0 1 3 13v-3a4 4 0 1 1 8 0zm-2-3v3a2 2 0 1 1-4 0v-3a2 2 0 1 1 4 0" />
    </g>
  );
}
export default AedPads;
