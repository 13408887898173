import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CloudSyncing(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CloudSyncingIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M17 10a4 4 0 0 0-8 0 1 1 0 0 1-1 1 2 2 0 0 0-2 2 1 1 0 0 1-1 1h-.5a1.5 1.5 0 0 0 0 3 1 1 0 1 1 0 2 3.5 3.5 0 0 1-.38-6.98 4.01 4.01 0 0 1 2.946-2.91A6.001 6.001 0 0 1 19 10v.027a4.5 4.5 0 0 1 4 4.473 1 1 0 1 1-2 0 2.5 2.5 0 0 0-2.5-2.5H18a1 1 0 0 1-1-1z" />
      <path d="M13.5 12.916c-2.194 0-3.971 1.533-4.572 3.566a.999.999 0 0 0-.965 1.653l1.013 1.09a1 1 0 0 0 1.465 0l1.012-1.09a1 1 0 0 0-.4-1.624c.486-.97 1.43-1.595 2.447-1.595.512 0 .993.152 1.41.423a1 1 0 0 0 1.088-1.678 4.57 4.57 0 0 0-2.498-.745m4.573 6.601a1 1 0 0 0 .964-1.653l-1.012-1.09a1 1 0 0 0-1.466 0l-1.012 1.09a1 1 0 0 0 .4 1.624c-.486.97-1.43 1.595-2.447 1.595a2.57 2.57 0 0 1-1.41-.422 1 1 0 1 0-1.088 1.678 4.57 4.57 0 0 0 2.498.744c2.194 0 3.972-1.533 4.573-3.566" />
    </g>
  );
}
export default CloudSyncing;
