import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Calendar(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CalendarIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M6.5 13a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m7-1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M12 18a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m7-6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M6.5 18a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
      <path d="M8 3a1 1 0 0 0-2 0v1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-3V3a1 1 0 1 0-2 0v1H8zm8 4a1 1 0 1 0 2 0V6h3v14H3V6h3v1a1 1 0 0 0 2 0V6h8z" />
    </g>
  );
}
export default Calendar;
