import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Pin(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PinIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.368 18.023c.576.576 1.568.612 2.157-.06 1.896-2.166 1.796-4.33 1.52-5.5a.5.5 0 0 1-.007-.196.2.2 0 0 1 .02-.055l.002-.002 2.733-2.733s.012-.01.04-.024a.7.7 0 0 1 .15-.045c.586-.116 1.527-.425 2.499-1.241.705-.592.647-1.597.079-2.165l-3.536-3.535c-.568-.568-1.572-.626-2.164.079-.816.971-1.125 1.912-1.241 2.5a.7.7 0 0 1-.045.148.2.2 0 0 1-.025.04l-2.732 2.733-.002.002-.008.005a.2.2 0 0 1-.047.015.5.5 0 0 1-.197-.006c-1.169-.276-3.334-.376-5.499 1.52-.672.589-.636 1.58-.06 2.156l2.475 2.475-5.049 5.049a1 1 0 1 0 1.414 1.414l5.049-5.049zm1.73-5.099c.155.653.26 1.897-.725 3.275l-5.545-5.545c1.379-.984 2.622-.879 3.275-.724.657.155 1.515.066 2.129-.548l2.733-2.733c.375-.375.543-.84.617-1.217.048-.242.166-.65.46-1.12l2.674 2.673a3.4 3.4 0 0 1-1.12.46c-.378.075-.842.243-1.217.618l-2.733 2.733c-.614.614-.704 1.471-.548 2.128"
      clipRule="evenodd"
    />
  );
}
export default Pin;
