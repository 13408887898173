import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function SendTo(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'SendToIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M17 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
        clipRule="evenodd"
      />
      <path d="M17 15a4 4 0 0 0-4 4 1 1 0 1 1-2 0 6 6 0 0 1 12 0 1 1 0 1 1-2 0 4 4 0 0 0-4-4m-8.83-2.408H2c-.55 0-1-.45-1-1s.45-1 1-1h6.17l-1.88-1.89a.996.996 0 1 1 1.41-1.41l3.59 3.59c.39.39.39 1.02 0 1.41l-3.59 3.59a.996.996 0 1 1-1.41-1.41z" />
    </g>
  );
}
export default SendTo;
