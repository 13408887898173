import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Legend7Pentagon(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'Legend7PentagonIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M17.998 19.59A2 2 0 0 1 16.087 21H7.913a2 2 0 0 1-1.911-1.41l-2.59-8.382a2 2 0 0 1 .697-2.18l6.677-5.1a2 2 0 0 1 2.428 0l6.677 5.1a2 2 0 0 1 .697 2.18z"
    />
  );
}
export default Legend7Pentagon;
