import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function GearSettings(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'GearSettingsIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M15.97 12a3.97 3.97 0 1 0-7.94 0 3.97 3.97 0 0 0 7.94 0m-2 0a1.97 1.97 0 1 1-3.94 0 1.97 1.97 0 0 1 3.94 0" />
      <path d="M9.441 2.57a1.91 1.91 0 0 1-2.3.953C4.91 2.794 2.795 4.91 3.524 7.142a1.91 1.91 0 0 1-.952 2.3c-2.095 1.062-2.095 4.054 0 5.117a1.91 1.91 0 0 1 .952 2.3c-.729 2.232 1.386 4.347 3.619 3.618a1.91 1.91 0 0 1 2.3.952c1.062 2.095 4.054 2.095 5.117 0a1.91 1.91 0 0 1 2.3-.952c2.232.729 4.347-1.386 3.618-3.619a1.91 1.91 0 0 1 .952-2.3c2.095-1.062 2.095-4.054 0-5.117a1.91 1.91 0 0 1-.952-2.3c.729-2.232-1.386-4.347-3.619-3.618a1.91 1.91 0 0 1-2.3-.952c-1.062-2.095-4.054-2.095-5.117 0Zm-2.92 2.855a3.914 3.914 0 0 0 4.705-1.949.868.868 0 0 1 1.548 0 3.914 3.914 0 0 0 4.706 1.95.868.868 0 0 1 1.095 1.094 3.914 3.914 0 0 0 1.949 4.706.868.868 0 0 1 0 1.548 3.914 3.914 0 0 0-1.95 4.706.868.868 0 0 1-1.094 1.095 3.914 3.914 0 0 0-4.706 1.949.868.868 0 0 1-1.548 0 3.914 3.914 0 0 0-4.706-1.95.868.868 0 0 1-1.095-1.094 3.914 3.914 0 0 0-1.949-4.706.868.868 0 0 1 0-1.548 3.914 3.914 0 0 0 1.95-4.706.868.868 0 0 1 1.094-1.095Z" />
    </g>
  );
}
export default GearSettings;
