import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ForwardsFiveSec(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ForwardsFiveSecIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M10.5 21a1 1 0 1 0 0-2 7.5 7.5 0 1 1 7.493-7.836l-1.114-1.114a1 1 0 1 0-1.415 1.414l2.829 2.829a1 1 0 0 0 1.414 0l2.829-2.828a1 1 0 0 0-1.415-1.415l-1.126 1.127A9.5 9.5 0 1 0 10.5 21" />
      <path d="M12 7H8v3.948C8 11.53 8.47 12 9.052 12H10a1 1 0 1 1 0 2H9a1 1 0 1 0 0 2h1a3 3 0 1 0 0-6V9h2a1 1 0 1 0 0-2" />
    </g>
  );
}
export default ForwardsFiveSec;
