import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Notification(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'NotificationIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M14.567 5.554a3 3 0 1 0-5.134 0 6.22 6.22 0 0 0-3.521 4.383l-1.658 7.857A1 1 0 0 0 5.233 19h13.534a1 1 0 0 0 .978-1.206l-1.657-7.857a6.22 6.22 0 0 0-3.521-4.383M12 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2m4.13 5.35L17.535 17H6.466l1.403-6.65a4.222 4.222 0 0 1 8.262 0ZM13 21c.552 0 1.02.474.755.959a2 2 0 0 1-3.51 0c-.266-.485.203-.959.755-.959z"
    />
  );
}
export default Notification;
