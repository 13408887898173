import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CloudAttention(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'CloudAttentionIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M17 10a4 4 0 0 0-8 0 1 1 0 0 1-1 1 2 2 0 0 0-2 2 1 1 0 0 1-1 1h-.5a1.5 1.5 0 0 0 0 3H8a1 1 0 1 1 0 2H4.5a3.5 3.5 0 0 1-.38-6.98 4.01 4.01 0 0 1 2.946-2.91A6.001 6.001 0 0 1 19 10v.027A4.5 4.5 0 0 1 18.5 19H18a1 1 0 1 1 0-2h.5a2.5 2.5 0 0 0 0-5h-.499A1 1 0 0 1 17 11z" />
      <path d="M13 11a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1m0 10.517a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
    </g>
  );
}
export default CloudAttention;
