import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function History(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'HistoryIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M2.293 5.536a1 1 0 0 1 0-1.414l.01-.01 2.818-2.82a1 1 0 0 1 1.415 1.415L5.243 4H12.5A9.5 9.5 0 1 1 3 13.5a1 1 0 0 1 2 0 7.5 7.5 0 0 0 7.5 7.5h.008A7.5 7.5 0 0 0 12.5 6H5.586l.95.95A1 1 0 1 1 5.12 8.364L2.3 5.542z" />
      <path d="M12 8a1 1 0 0 0-1 1v4a1 1 0 0 0 .293.707l2.828 2.829a1 1 0 0 0 1.415-1.415L13 12.586V9a1 1 0 0 0-1-1" />
    </g>
  );
}
export default History;
