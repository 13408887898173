import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Facebook(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'FacebookIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M10 19.95V13H8v-3h2V7.5C10 5.57 11.57 4 13.5 4H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95z"
    />
  );
}
export default Facebook;
