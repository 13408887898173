import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function MoodVerySad(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'MoodVerySadIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M15.25 16.66a1 1 0 0 0 1.5-1.32C15.507 13.924 13.862 13 12 13c-1.861 0-3.506.925-4.75 2.34a1 1 0 1 0 1.5 1.32C9.712 15.57 10.845 15 12 15c1.156 0 2.289.57 3.25 1.66M6.768 10.5a1 1 0 0 1 .366-1.366l1.732-1a1 1 0 0 1 1 1.732l-1.732 1a1 1 0 0 1-1.366-.366m10.098-1.366a1 1 0 1 1-1 1.732l-1.732-1a1 1 0 1 1 1-1.732z" />
      <path
        fillRule="evenodd"
        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11M3 12a9 9 0 1 0 18 0 9 9 0 0 0-18 0"
        clipRule="evenodd"
      />
    </g>
  );
}
export default MoodVerySad;
