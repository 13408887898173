import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Hierarchy(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'HierarchyIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M14 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-3 9H6a1 1 0 0 0-1 1v1a1 1 0 1 1-2 0v-1a3 3 0 0 1 3-3h5V9a1 1 0 1 1 2 0v2h5a3 3 0 0 1 3 3v1a1 1 0 1 1-2 0v-1a1 1 0 0 0-1-1h-5v2a1 1 0 1 1-2 0zm1 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4m10-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0M4 22a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
    />
  );
}
export default Hierarchy;
