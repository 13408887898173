import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function VisibleOn(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'VisibleOnIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
      <path d="m1.916 10.34-.737 1.078c-.239.35-.239.813 0 1.164l.737 1.078c4.862 7.12 15.306 7.12 20.168 0l.737-1.078c.239-.35.239-.813 0-1.164l-.737-1.078c-4.862-7.12-15.306-7.12-20.168 0m18.517 1.128.363.532-.363.532c-4.069 5.957-12.797 5.957-16.866 0L3.204 12l.363-.532c4.069-5.957 12.797-5.957 16.866 0" />
    </g>
  );
}
export default VisibleOn;
