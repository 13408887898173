import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function BookmarkCollection(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'BookmarkCollectionIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M19 4v10.296l-.543-.242a1.045 1.045 0 0 0-.875 1.897l1.932.92A1 1 0 0 0 21 15.996V4a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3c0 .552.448 1.004 1 1.004S11 4.552 11 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
      <path
        fillRule="evenodd"
        d="M13.514 22.87 9 20.247 4.486 22.87A1 1 0 0 1 3 21.997V10a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v11.997a1 1 0 0 1-1.486.873M5 20.297 8.028 18.5a2 2 0 0 1 1.944 0L13 20.296V10a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v10.296Z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default BookmarkCollection;
