import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Educator(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'EducatorIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M1 6a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4v9a1 1 0 1 1-2 0V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h1.21a1 1 0 1 1 0 2H5a4 4 0 0 1-4-4z" />
      <path d="M17 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0m1 7v5a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4.69l-2.491-1.44a1 1 0 0 1-.476-.612L7.74 9.428a1 1 0 0 1 1.932-.517l1.187 4.43 1.668.962A3 3 0 0 1 18 16" />
    </g>
  );
}
export default Educator;
