import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Mail(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'MailIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M1 7v10a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3m17.985-1L12 12.985 5.015 6zm-15.97.828 7.596 7.597c.767.767 2.01.767 2.777 0l7.597-7.597Q21 6.912 21 7v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7q0-.088.015-.172"
    />
  );
}
export default Mail;
