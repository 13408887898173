import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Link(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LinkIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M12.243 5.172a1 1 0 1 0 1.414 1.414l.707-.707a3 3 0 1 1 4.242 4.243l-2.828 2.828a3 3 0 0 1-4.242 0 1 1 0 0 0-1.415 1.414 5 5 0 0 0 7.071 0l2.829-2.828a5 5 0 1 0-7.071-7.071z" />
      <path d="m11.536 20.02.707-.706a1 1 0 1 0-1.415-1.415l-.707.707a3 3 0 1 1-4.242-4.242l2.828-2.829a3 3 0 0 1 4.243 0 1 1 0 0 0 1.414-1.414 5 5 0 0 0-7.071 0L4.464 12.95a5 5 0 0 0 7.072 7.07" />
    </g>
  );
}
export default Link;
