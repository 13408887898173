import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ShockNotAdvised(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ShockNotAdvisedIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M4.728 1.315a1 1 0 1 0-1.456 1.37l16 17a1 1 0 0 0 1.456-1.37l-4.854-5.158L18.84 8.54A1 1 0 0 0 18 7h-3.383l2.276-4.554A1 1 0 0 0 16 1H9a1 1 0 0 0-.98.804l-.497 2.48-2.795-2.97ZM9.2 6.065l5.262 5.59L16.168 9H13a1 1 0 0 1-.894-1.447L14.382 3H9.82l-.59 2.946a1 1 0 0 1-.03.12Z"
        clipRule="evenodd"
      />
      <path d="M8.23 10.946a1 1 0 1 0-1.96-.392l-.25 1.25A1 1 0 0 0 7 13h1.5a1 1 0 1 0 0-2h-.28zm3.25 3.75a1 1 0 1 0-1.96-.392l-1.5 7.5a1 1 0 0 0 1.821.737l3.375-5.25a1 1 0 1 0-1.682-1.082l-.517.804z" />
    </g>
  );
}
export default ShockNotAdvised;
