import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function LegendStrokeSolid(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'LegendStrokeSolidIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12m0 2a8 8 0 1 0 0-16 8 8 0 0 0 0 16"
      clipRule="evenodd"
    />
  );
}
export default LegendStrokeSolid;
