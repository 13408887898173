import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function TimeLimited(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TimeLimitedIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M12 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4m-.707 8.321a1 1 0 0 0 1.414 1.415l2.829-2.829a1 1 0 1 0-1.415-1.414z" />
      <path d="M12 5a9 9 0 1 0 0 18 9 9 0 0 0 0-18m7 9a7 7 0 1 1-14 0 7 7 0 0 1 14 0m.879-9.707a1 1 0 1 0-1.414 1.414l1.84 1.84a1 1 0 0 0 1.413-1.415l-1.839-1.84Z" />
    </g>
  );
}
export default TimeLimited;
