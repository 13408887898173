import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ChevronRight(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ChevronRightIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.803 19.708a1 1 0 0 1-.002-1.414L15.079 12 8.8 5.706a1 1 0 1 1 1.416-1.412l6.982 7a1 1 0 0 1 0 1.412l-6.982 7a1 1 0 0 1-1.414.002Z"
      clipRule="evenodd"
    />
  );
}
export default ChevronRight;
