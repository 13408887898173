import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Manikin(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ManikinIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M14 6a4 4 0 0 0 0 8h7.555c.798 0 1.445-.647 1.445-1.444V7.445C23 6.647 22.353 6 21.555 6zm7 2v4h-7a2 2 0 1 1 0-4zM4.5 14a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m0-2a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M2 17a1 1 0 1 0 0 2h20a1 1 0 1 0 0-2z"
    />
  );
}
export default Manikin;
