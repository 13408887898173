import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExtendTextArea(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ExtendTextAreaIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M17.739 21.703a1 1 0 0 1 0-1.414l2.56-2.56a1 1 0 0 1 1.414 1.414l-2.56 2.56a1 1 0 0 1-1.414 0m-5.724.002a1 1 0 0 1 0-1.414l8.277-8.277a1 1 0 0 1 1.414 1.414l-8.277 8.277a1 1 0 0 1-1.414 0m-5.731.011a1 1 0 0 1 0-1.414l14.019-14.02a1 1 0 1 1 1.414 1.415L7.698 21.716a1 1 0 0 1-1.414 0"
    />
  );
}
export default ExtendTextArea;
