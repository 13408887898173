import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function LikeOff(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LikeOffIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.74 5.343a5.806 5.806 0 0 1 8.21 0l.07.07.07-.07a5.806 5.806 0 0 1 8.21 8.21l-7.564 7.565a1 1 0 0 1-.725.293 1 1 0 0 1-.707-.293l-7.565-7.564a5.806 5.806 0 0 1 0-8.211Zm8.28 13.663 6.866-6.866a3.806 3.806 0 0 0-5.382-5.383l-.768.767a1 1 0 0 1-.708.293 1 1 0 0 1-.724-.292l-.768-.768a3.806 3.806 0 0 0-5.382 5.382z"
      clipRule="evenodd"
    />
  );
}
export default LikeOff;
