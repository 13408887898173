import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function LegendStrokeDashed(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'LegendStrokeDashedIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.93 13.068a.476.476 0 0 1-.573.396l-.98-.194a.534.534 0 0 1-.415-.59 6 6 0 0 0 0-1.36.534.534 0 0 1 .414-.59l.981-.194a.476.476 0 0 1 .572.396 8 8 0 0 1 0 2.136Zm-1.568-5.92a.477.477 0 0 1-.125.685l-.83.557a.534.534 0 0 1-.712-.126 6 6 0 0 0-.96-.96.534.534 0 0 1-.125-.71l.557-.831a.477.477 0 0 1 .684-.125 8 8 0 0 1 1.51 1.51Zm-5.294-3.077c.274.036.45.3.396.572l-.194.98a.534.534 0 0 1-.59.415 6 6 0 0 0-1.36 0 .534.534 0 0 1-.59-.414l-.194-.981a.476.476 0 0 1 .396-.572 8 8 0 0 1 2.136 0m-5.92 1.567a.477.477 0 0 1 .685.125l.557.83c.153.23.09.54-.126.711a6 6 0 0 0-.96.96.534.534 0 0 1-.71.126l-.831-.557a.477.477 0 0 1-.125-.684 8 8 0 0 1 1.51-1.51Zm-2.505 4.898a.476.476 0 0 0-.572.396 8 8 0 0 0 0 2.136c.036.274.3.45.572.396l.98-.194a.534.534 0 0 0 .415-.59 6 6 0 0 1 0-1.36.534.534 0 0 0-.414-.59zm.995 6.316a.477.477 0 0 1 .125-.685l.83-.557a.534.534 0 0 1 .711.126q.426.534.96.96a.534.534 0 0 1 .126.71l-.557.831a.477.477 0 0 1-.684.125 8 8 0 0 1-1.51-1.51Zm5.294 3.077a.476.476 0 0 1-.396-.572l.194-.98a.534.534 0 0 1 .59-.415 6 6 0 0 0 1.36 0 .534.534 0 0 1 .59.414l.194.981a.476.476 0 0 1-.396.572 8 8 0 0 1-2.136 0m5.92-1.567a.477.477 0 0 1-.685-.125l-.557-.83a.534.534 0 0 1 .126-.712 6 6 0 0 0 .96-.96.534.534 0 0 1 .71-.125l.831.557c.23.153.292.465.125.684a8 8 0 0 1-1.51 1.51Z"
      clipRule="evenodd"
    />
  );
}
export default LegendStrokeDashed;
