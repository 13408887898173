import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Stack(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'StackIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M10.41 1.453a3 3 0 0 1 3.18 0l5.583 3.49a2 2 0 0 1 0 3.391l-5.583 3.49a3 3 0 0 1-3.18 0l-5.583-3.49a2 2 0 0 1 0-3.392l5.583-3.49Zm2.12 1.696a1 1 0 0 0-1.06 0l-5.583 3.49 5.583 3.489a1 1 0 0 0 1.06 0l5.583-3.49-5.583-3.49Z"
        clipRule="evenodd"
      />
      <path d="M19.841 11.968a1 1 0 0 1-.3 1.382l-5.919 3.805a3 3 0 0 1-3.244 0L4.459 13.35a1 1 0 1 1 1.082-1.682l5.918 3.805a1 1 0 0 0 1.082 0l5.918-3.805a1 1 0 0 1 1.382.3" />
      <path d="M19.54 18.72a1 1 0 0 0-1.08-1.682l-5.92 3.805a1 1 0 0 1-1.08 0l-5.92-3.805a1 1 0 1 0-1.08 1.683l5.918 3.804a3 3 0 0 0 3.244 0l5.919-3.804Z" />
    </g>
  );
}
export default Stack;
