import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowLineRight(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ArrowLineRightIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M5 13.005h11.587l-2.88 2.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41l-4.59-4.59a.996.996 0 1 0-1.41 1.41l2.88 2.89H5c-.55 0-1 .45-1 1s.45 1 1 1"
    />
  );
}
export default ArrowLineRight;
