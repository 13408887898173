import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function FolderNew(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'FolderNewIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M16 9.018a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1v-1a1 1 0 0 1 1-1" />
      <path
        fillRule="evenodd"
        d="M1 4.5a2 2 0 0 1 2-2h6.65a2 2 0 0 1 1.249.438L12.85 4.5H21a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2zm8.65 0H3v13h18v-11h-8.15a2 2 0 0 1-1.249-.438z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default FolderNew;
