import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function GuidedTour(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'GuidedTourIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.398 1.202a1 1 0 0 1 .877-.164l10.634 3.039c1.934.553 1.934 3.293 0 3.846L8 10.754V22a1 1 0 1 1-2 0V2a1 1 0 0 1 .398-.798M8 8.674 17.36 6 8 3.326z"
      clipRule="evenodd"
    />
  );
}
export default GuidedTour;
