import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Twitter(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TwitterIcon' },
      props
    ),
    <path
      fill="#1F1F1F"
      d="M9.71 18.192c5.658 0 8.754-4.692 8.754-8.754q.002-.198-.006-.396a6.3 6.3 0 0 0 1.536-1.596 6.2 6.2 0 0 1-1.77.486 3.1 3.1 0 0 0 1.356-1.704 6.1 6.1 0 0 1-1.956.744A3.07 3.07 0 0 0 15.38 6a3.08 3.08 0 0 0-3 3.78 8.74 8.74 0 0 1-6.342-3.216 3.085 3.085 0 0 0 .954 4.11A3.1 3.1 0 0 1 5.6 10.29v.042a3.08 3.08 0 0 0 2.466 3.018 3 3 0 0 1-.81.108q-.297 0-.576-.054a3.08 3.08 0 0 0 2.874 2.136 6.17 6.17 0 0 1-3.822 1.32A6 6 0 0 1 5 16.818a8.75 8.75 0 0 0 4.71 1.374"
    />
  );
}
export default Twitter;
