import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function VolumeOff(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'VolumeOffIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m7.586 18.586-2.42-2.42H3a2 2 0 0 1-2-2V9.833a2 2 0 0 1 2-2h2.167l2.419-2.419C8.846 4.154 11 5.046 11 6.828v10.343c0 1.782-2.154 2.675-3.414 1.415m-1.59-8.753H3v4.333h2.995L9 17.171V6.828L5.995 9.833Zm10.72-1.039a1.005 1.005 0 1 0-1.422 1.422L17.078 12l-1.784 1.784a1.005 1.005 0 0 0 1.422 1.421l1.784-1.784 1.784 1.784a1.005 1.005 0 0 0 1.421-1.422L19.922 12l1.783-1.784a1.005 1.005 0 1 0-1.421-1.422L18.5 10.578z"
    />
  );
}
export default VolumeOff;
