import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Book(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'BookIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M6.175 10.07A4.7 4.7 0 0 1 7 10a4.7 4.7 0 0 1 .825.07 1 1 0 0 0 .35-1.97A7 7 0 0 0 7 8a7 7 0 0 0-1.175.1 1 1 0 0 0 .35 1.97M7 13.715c-.296 0-.574.026-.825.07a1 1 0 0 1-.35-1.969c.37-.066.766-.1 1.175-.1s.804.034 1.175.1a1 1 0 1 1-.35 1.97 5 5 0 0 0-.825-.07Zm9.175-3.645A4.7 4.7 0 0 1 17 10a4.7 4.7 0 0 1 .825.07 1 1 0 0 0 .35-1.97A7 7 0 0 0 17 8a7 7 0 0 0-1.175.1 1 1 0 0 0 .35 1.97M17 13.715c-.296 0-.574.026-.825.07a1 1 0 0 1-.35-1.969 7 7 0 0 1 1.175-.1c.409 0 .804.034 1.175.1a1 1 0 1 1-.35 1.97 5 5 0 0 0-.825-.07Z" />
      <path d="M1 18.739q0 .066.008.131a1 1 0 0 0 1.44 1.024c1.424-.713 2.957-1.099 4.552-1.099a10.1 10.1 0 0 1 4.6 1.121 1 1 0 0 0 .858-.027A10.1 10.1 0 0 1 17 18.795c1.595 0 3.128.386 4.552 1.099a1 1 0 0 0 1.44-1.025 1 1 0 0 0 .008-.13V6.42a1 1 0 0 0-.006-.106 1 1 0 0 0-.546-1.005C20.763 4.467 18.925 4 17 4c-1.756 0-3.44.388-5 1.097A12 12 0 0 0 7 4c-1.926 0-3.763.467-5.448 1.31a1 1 0 0 0-.546 1.003A1 1 0 0 0 1 6.42v12.318ZM7 6c1.391 0 2.734.293 4 .84v10.648a11.9 11.9 0 0 0-4-.693 11.9 11.9 0 0 0-4 .693V6.841A10 10 0 0 1 7 6m14 11.488a11.9 11.9 0 0 0-4-.693c-1.387 0-2.729.243-4 .693V6.841A10 10 0 0 1 17 6c1.391 0 2.735.293 4 .84z" />
    </g>
  );
}
export default Book;
