import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ClosedCaptionOn(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ClosedCaptionOnIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M5 11a3 3 0 0 1 5.122-2.12l.001-.001a1 1 0 0 1-1.41 1.418v.001A1 1 0 0 0 7 11v2a1 1 0 0 0 1.711.702h.001a1 1 0 0 1 1.41 1.42v-.002A3 3 0 0 1 5 13zm11-3a3 3 0 0 0-3 3v2a3 3 0 0 0 5.122 2.12l.001.001a1 1 0 0 0-1.41-1.418v-.001A1 1 0 0 1 15 13v-2a1 1 0 0 1 1.711-.702h.001a1 1 0 0 0 1.41-1.42v.002A3 3 0 0 0 16 8" />
      <path d="M4 4a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm16 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1z" />
    </g>
  );
}
export default ClosedCaptionOn;
