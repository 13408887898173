import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Discount(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'DiscountIcon' },
      props
    ),
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M19 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
      <path d="M23 4.035a3 3 0 0 0-3-3h-5.33a5 5 0 0 0-3.556 1.486l-9.248 9.356a3 3 0 0 0 .024 4.242l6.071 6.008a3 3 0 0 0 4.22-.001l9.336-9.242a5 5 0 0 0 1.482-3.553zm-3-1a1 1 0 0 1 1 1v5.296a3 3 0 0 1-.89 2.132l-9.335 9.242a1 1 0 0 1-1.407 0l-6.072-6.008a1 1 0 0 1-.007-1.414l9.247-9.356a3 3 0 0 1 2.134-.892z" />
    </g>
  );
}
export default Discount;
