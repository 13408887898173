import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function MoodSad(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'MoodSadIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M8.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M17 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-1.494 7.37a1 1 0 0 0 .988-1.74C15.23 14.412 13.669 14 12 14c-1.67 0-3.23.412-4.494 1.13a1 1 0 0 0 .988 1.74C9.435 16.333 10.652 16 12 16s2.564.334 3.506.87" />
      <path
        fillRule="evenodd"
        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11M3 12a9 9 0 1 0 18 0 9 9 0 0 0-18 0"
        clipRule="evenodd"
      />
    </g>
  );
}
export default MoodSad;
