import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Shock(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ShockIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.02 1.804A1 1 0 0 1 9 1h7a1 1 0 0 1 .894 1.447L14.618 7H18a1 1 0 0 1 .841 1.54l-9 14a1 1 0 0 1-1.822-.736L9.78 13H7a1 1 0 0 1-.98-1.196zM9.82 3l-1.6 8H11a1 1 0 0 1 .98 1.196l-.963 4.817L16.168 9H13a1 1 0 0 1-.894-1.447L14.382 3z"
      clipRule="evenodd"
    />
  );
}
export default Shock;
