import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function TwitterX(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TwitterXIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m3.044 3.576 6.95 9.292L3 20.423h1.574l6.123-6.614 4.947 6.614H21l-7.34-9.814 6.509-7.033h-1.574l-5.639 6.092L8.4 3.576zm2.315 1.16h2.46l10.866 14.528h-2.46L5.357 4.736Z"
    />
  );
}
export default TwitterX;
