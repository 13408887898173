import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Bluetooth(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'BluetoothIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.617 1.076a1 1 0 0 1 1.09.217l5 5a1 1 0 0 1 0 1.414L13.414 12l4.293 4.293a1 1 0 0 1 0 1.414l-5 5A1 1 0 0 1 11 22v-7.586l-4.293 4.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L11 9.586V2a1 1 0 0 1 .617-.924M13 14.414 15.586 17 13 19.586zm0-4.828V4.414L15.586 7z"
      clipRule="evenodd"
    />
  );
}
export default Bluetooth;
