import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Playlist(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PlaylistIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M5 4a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2zm0 5a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2zm-1 6a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m13.581-.87a1 1 0 0 0-1.581.813v6.114a1 1 0 0 0 1.581.814l4.28-3.057a1 1 0 0 0 0-1.628z"
    />
  );
}
export default Playlist;
