import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function AddUser(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'AddUserIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M9 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
        clipRule="evenodd"
      />
      <path d="M19 7a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2zM4 19a5 5 0 0 1 10 0v2a1 1 0 1 0 2 0v-2a7 7 0 1 0-14 0v2a1 1 0 1 0 2 0z" />
    </g>
  );
}
export default AddUser;
