import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CloudNoConnection(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'CloudNoConnectionIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M17 10a4 4 0 0 0-8 0 1 1 0 0 1-1 1 2 2 0 0 0-2 2 1 1 0 0 1-1 1h-.5a1.5 1.5 0 0 0 0 3H6a1 1 0 1 1 0 2H4.5a3.5 3.5 0 0 1-.38-6.98 4.01 4.01 0 0 1 2.946-2.91A6.001 6.001 0 0 1 19 10v.027A4.5 4.5 0 0 1 18.5 19a1 1 0 1 1 0-2 2.5 2.5 0 0 0 0-5H18a1 1 0 0 1-1-1z" />
      <path d="M9.431 20.655a1 1 0 0 1 0-1.414l1.443-1.444-1.59-1.59a1 1 0 1 1 1.415-1.414l1.59 1.59 1.59-1.59a1 1 0 0 1 1.414 1.414l-1.59 1.59 1.443 1.444a1 1 0 0 1-1.414 1.414l-1.443-1.444-1.444 1.444a1 1 0 0 1-1.414 0" />
    </g>
  );
}
export default CloudNoConnection;
