import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowExpand(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ArrowExpandIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m20.071 5.415-5.365 5.365a1.003 1.003 0 0 1-1.414 0 1.003 1.003 0 0 1 0-1.414l5.365-5.365-3.08-.007a.996.996 0 1 1 0-1.994h5.491c.552 0 .997.445.997.997v5.491a.996.996 0 1 1-1.994 0zM3.994 18.657l5.365-5.365a1.003 1.003 0 0 1 1.414 0 1.003 1.003 0 0 1 0 1.414L5.408 20.07l3.08.007a.996.996 0 1 1 0 1.994H2.997A.996.996 0 0 1 2 21.075v-5.491a.996.996 0 1 1 1.994 0z"
    />
  );
}
export default ArrowExpand;
