import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CheckboxOff(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CheckboxOffIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M18.004 3.996a2 2 0 0 1 2 2V18a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5.996a2 2 0 0 1 2-2zM6 1.996a4 4 0 0 0-4 4V18a4 4 0 0 0 4 4h12.004a4 4 0 0 0 4-4V5.996a4 4 0 0 0-4-4z"
    />
  );
}
export default CheckboxOff;
