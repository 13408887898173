import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowDropRight(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ArrowDropRightIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M10.834 16.679c-.677.686-1.834.2-1.834-.771V8.092c0-.97 1.157-1.457 1.834-.77l3.851 3.907c.42.426.42 1.116 0 1.542z"
    />
  );
}
export default ArrowDropRight;
