import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Login(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LoginIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M11 21a1 1 0 0 1 1-1h6a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-6a1 1 0 1 1 0-2h6a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4h-6a1 1 0 0 1-1-1" />
      <path d="M8.707 7.757a1 1 0 0 0-1.414 1.415L9.12 11H3a1 1 0 1 0 0 2h6.121l-1.828 1.829a1 1 0 1 0 1.414 1.414l3.536-3.536a1 1 0 0 0 0-1.414z" />
    </g>
  );
}
export default Login;
