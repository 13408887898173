import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Record(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'RecordIcon' },
      props
    ),
    <path fill="currentColor" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0" />
  );
}
export default Record;
