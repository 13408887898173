import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CloudLocked(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CloudLockedIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M17 10a4 4 0 0 0-8 0 1 1 0 0 1-1 1 2 2 0 0 0-2 2 1 1 0 0 1-1 1h-.5a1.5 1.5 0 0 0 0 3H5a1 1 0 1 1 0 2h-.5a3.5 3.5 0 0 1-.38-6.98 4.01 4.01 0 0 1 2.946-2.91A6.001 6.001 0 0 1 19 10v.027a4.5 4.5 0 0 1 .655 8.823 1 1 0 0 1-.512-1.933A2.501 2.501 0 0 0 18.5 12H18a1 1 0 0 1-1-1z" />
      <path d="M9.368 16v.004a1 1 0 0 0-.911.996v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-.911-.996v-1.915a3.089 3.089 0 0 0-6.178 0zm4.178-1.911V16h-2.178v-1.911a1.089 1.089 0 1 1 2.178 0m.91 3.911v2h-4v-2z" />
    </g>
  );
}
export default CloudLocked;
