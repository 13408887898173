import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Rewind(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'RewindIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M1.355 11.26c-.473.332-.473 1.148 0 1.479l8.56 6.001c.48.337 1.085-.076 1.085-.739v-6c0 .286.118.572.355.738l8.56 6.001c.48.337 1.085-.076 1.085-.739V6c0-.664-.605-1.076-1.084-.74l-8.561 6.002c-.237.166-.355.453-.355.74V5.998c0-.664-.605-1.076-1.084-.74z"
    />
  );
}
export default Rewind;
