import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Attachment(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'AttachmentIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 3a3 3 0 0 0-3 3v10a5 5 0 0 0 10 0V6a1 1 0 1 1 2 0v10a7 7 0 1 1-14 0V6a5 5 0 0 1 10 0v9.454a3 3 0 1 1-6 0V6a1 1 0 0 1 2 0v9.454a1 1 0 1 0 2 0V6a3 3 0 0 0-3-3"
      clipRule="evenodd"
    />
  );
}
export default Attachment;
