import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Bullet(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'BulletIcon' },
      props
    ),
    <path fill="currentColor" d="M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0" />
  );
}
export default Bullet;
