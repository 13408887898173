import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Play(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PlayIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M20.503 11.142a.996.996 0 0 1 0 1.724L8.518 19.864C7.847 20.256 7 19.776 7 19.002V5.005c0-.773.847-1.254 1.518-.862z"
    />
  );
}
export default Play;
