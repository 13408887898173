import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Legend2Triangle(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'Legend2TriangleIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M9.734 5.29c1.019-1.72 3.513-1.72 4.532 0l6.364 10.747C21.667 17.788 20.402 20 18.364 20H5.636c-2.038 0-3.303-2.212-2.266-3.963z"
    />
  );
}
export default Legend2Triangle;
