import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Accessibility(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'AccessibilityIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M14 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
      <path
        fillRule="evenodd"
        d="M4.221 7.025a1 1 0 1 0-.442 1.95c1.925.437 3.613.747 5.221.92v5.022l-.986 5.918a1 1 0 0 0 1.973.33l.86-5.165h2.306l.86 5.164a1 1 0 0 0 1.973-.329L15 14.916V9.89c1.594-.173 3.285-.479 5.22-.914a1 1 0 1 0-.44-1.952c-3.132.706-5.514 1.04-7.85 1.036s-4.67-.345-7.709-1.035M13 10.04a26 26 0 0 1-2 .002V14h2z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default Accessibility;
