import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function OpenFolder(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'OpenFolderIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M1 18a2 2 0 0 0 2 2h16.704q.038 0 .074-.003a.985.985 0 0 0 1.037-.718l2.152-8.034a.97.97 0 0 0-.192-.87 1 1 0 0 0-.78-.375h-1V8a2 2 0 0 0-2-2h-7.998a2 2 0 0 0-1.999-2H2.999A2 2 0 0 0 1 6zm17.995-8H6q-.074 0-.144.01a1.01 1.01 0 0 0-.888.747L3.027 18h-.028V6h5.999a2 2 0 0 0 2 2h7.997zM6.704 12h13.99l-1.607 6H5.097z"
    />
  );
}
export default OpenFolder;
