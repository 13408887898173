import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Cloud(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CloudIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M18.529 19A4.5 4.5 0 0 0 19 10.027V10a6 6 0 0 0-11.934-.89 4.01 4.01 0 0 0-2.945 2.91A3.5 3.5 0 0 0 4.5 19zM13 6a4 4 0 0 1 4 4v1a1 1 0 0 0 1 1h.5a2.5 2.5 0 0 1 0 5h-14a1.5 1.5 0 0 1 0-3H5a1 1 0 0 0 1-1 2 2 0 0 1 2-2 1 1 0 0 0 1-1 4 4 0 0 1 4-4"
    />
  );
}
export default Cloud;
