import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Legend1Circle(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'Legend1CircleIcon',
      },
      props
    ),
    <circle cx={12} cy={12} r={8} fill="currentColor" />
  );
}
export default Legend1Circle;
