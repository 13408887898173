import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Replay(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ReplayIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m6.015 11.57 1.278-1.277a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414l1.303 1.303A8 8 0 1 1 12 20a1 1 0 1 1 0-2 6 6 0 1 0-5.985-6.43"
    />
  );
}
export default Replay;
