import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function PointUp(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PointUpIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M15 4v2.265a3 3 0 0 0-3.484 1.146 3 3 0 0 0-4.045.974A3 3 0 0 0 3 11v2q0 .11.008.22H3V16a7 7 0 0 0 7 7h4a7 7 0 0 0 7-7V4a3 3 0 1 0-6 0M5 15.83a3 3 0 0 0 3.529-1.214c.434.244.936.384 1.471.384.53 0 1.028-.137 1.46-.379.154.286.352.554.593.795l1.898 1.898a1 1 0 0 0 1.414-1.414l-1.898-1.898A1.417 1.417 0 0 1 15 11.686v.011h.027q.245.104.445.301l3.193 3.194c.215.214.335.505.335.808a5 5 0 0 1-5 5h-4a5 5 0 0 1-5-5zM11 10v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 2 0m-4 1v2a1 1 0 1 1-2 0v-2a1 1 0 1 1 2 0m10-.302V4a1 1 0 1 1 2 0v8.698zm-2-1.074a3.43 3.43 0 0 0-2 .29v-.82a1 1 0 1 1 2 0z"
    />
  );
}
export default PointUp;
