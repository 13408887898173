import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Information(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'InformationIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M13.5 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M13 11a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0z" />
      <path d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11m0-2a9 9 0 1 1 0-18 9 9 0 0 1 0 18" />
    </g>
  );
}
export default Information;
