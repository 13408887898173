import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ExportPng(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportPngIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M7.5 14.6a.4.4 0 0 0-.1.013V13.4h.8a.4.4 0 0 1 .4.4v.4a.4.4 0 0 1-.4.4z" />
      <path
        fillRule="evenodd"
        d="M4 3a2 2 0 0 1 2-2h7.172a2 2 0 0 1 1.414.586l4.828 4.828A2 2 0 0 1 20 7.828V21a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2 16v2h12v-2zm12-8H6V3h6v5a1 1 0 0 0 1 1h5zm-4-4h3.172L14 3.828zm-7 5.6a.4.4 0 0 0-.4.4v4a.4.4 0 0 0 .8 0v-1.613a.4.4 0 0 0 .1.013h.7a1.2 1.2 0 0 0 1.2-1.2v-.4a1.2 1.2 0 0 0-1.2-1.2zm4.09.188a.4.4 0 0 0-.74.212v4a.4.4 0 0 0 .8 0v-2.605l1.76 2.817a.4.4 0 0 0 .74-.212v-4a.4.4 0 0 0-.8 0v2.605zM16 13.4a.6.6 0 0 0-.6.6v2a.6.6 0 0 0 .6.6h.2a.4.4 0 0 0 .4-.4v-.3h-.1a.4.4 0 0 1 0-.8h.5a.4.4 0 0 1 .4.4v.7a1.2 1.2 0 0 1-1.2 1.2H16a1.4 1.4 0 0 1-1.4-1.4v-2a1.4 1.4 0 0 1 1.4-1.4h.2a1.2 1.2 0 0 1 1.2 1.2v.2a.4.4 0 0 1-.8 0v-.2a.4.4 0 0 0-.4-.4z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default ExportPng;
