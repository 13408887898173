import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Battery1(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'Battery1Icon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 14v-4h2V9a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-1zm4-5v6h12V9z"
      clipRule="evenodd"
    />
  );
}
export default Battery1;
