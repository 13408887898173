import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Loop(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LoopIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12 6a6 6 0 0 0-5.985 5.57l1.278-1.277a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414l1.303 1.303a8 8 0 0 1 12.114-6.453 1 1 0 0 1-1.032 1.714A5.97 5.97 0 0 0 12 6m5.985 6.43-1.278 1.277a1 1 0 0 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-1.303-1.303a8 8 0 0 1-12.114 6.453 1 1 0 1 1 1.033-1.713 6 6 0 0 0 9.076-4.714"
    />
  );
}
export default Loop;
