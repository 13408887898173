import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function BookmarkOn(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'BookmarkOnIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M18.514 22.87A1 1 0 0 0 20 21.997V4a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v17.997a1 1 0 0 0 1.486.873L12 19.247zm-2.6-14.033-4.992 4.992-2.836-2.837A1 1 0 1 1 9.5 9.578l1.422 1.423 3.579-3.578a1 1 0 1 1 1.414 1.414Z"
    />
  );
}
export default BookmarkOn;
