import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Download(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'DownloadIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M13 3a1 1 0 1 0-2 0v7.586L9.171 8.757a1 1 0 1 0-1.414 1.415l3.535 3.535A1 1 0 0 0 12 14c.287 0 .545-.12.727-.313l3.515-3.515a1 1 0 0 0-1.414-1.415L13 10.585z" />
      <path d="M3 14a1 1 0 0 1 1 1v3a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-3a1 1 0 1 1 2 0v3a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-3a1 1 0 0 1 1-1" />
    </g>
  );
}
export default Download;
