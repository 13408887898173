import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Legend5Diamond(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'Legend5DiamondIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M2.872 14.106a2.98 2.98 0 0 1 0-4.212l7.022-7.022a2.98 2.98 0 0 1 4.212 0l7.022 7.022a2.98 2.98 0 0 1 0 4.212l-7.022 7.022a2.98 2.98 0 0 1-4.212 0z"
    />
  );
}
export default Legend5Diamond;
