import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ChevronDown(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ChevronDownIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.292 8.803A1 1 0 0 1 5.706 8.8L12 15.079 18.294 8.8a1 1 0 1 1 1.412 1.416l-7 6.982a1 1 0 0 1-1.412 0l-7-6.982a1 1 0 0 1-.002-1.414Z"
      clipRule="evenodd"
    />
  );
}
export default ChevronDown;
