import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function PlayOutlined(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PlayOutlinedIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m18.006 12.004-8.991 5.25v-10.5zm2.497.862a.996.996 0 0 0 0-1.724L8.518 4.143C7.847 3.751 7 4.232 7 5.005v13.997c0 .774.847 1.254 1.518.862z"
    />
  );
}
export default PlayOutlined;
