import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ShockAdvised(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ShockAdvisedIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.02 1.804A1 1 0 0 1 5 1h7a1 1 0 0 1 .894 1.447L10.618 7H14a1 1 0 0 1 .841 1.54l-9 14a1 1 0 0 1-1.822-.736L5.78 13H3a1 1 0 0 1-.98-1.196zM5.82 3l-1.6 8H7a1 1 0 0 1 .98 1.196l-.963 4.817L12.168 9H9a1 1 0 0 1-.894-1.447L10.382 3zm10.35 19.985a1 1 0 0 0 .8-.742l1.25-5 .852 2.128A1 1 0 0 0 20 20h2a1 1 0 1 0 0-2h-1.323l-1.748-4.371a1 1 0 0 0-1.899.129l-1.452 5.806-.746-1.119A1 1 0 0 0 14 18h-2a1 1 0 1 0 0 2h1.465l1.703 2.555a1 1 0 0 0 1.003.43Z"
      clipRule="evenodd"
    />
  );
}
export default ShockAdvised;
