import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function PointLeft(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PointLeftIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M4 9h2.265a3 3 0 0 0 1.146 3.484 2.997 2.997 0 0 0 .974 4.045A3 3 0 0 0 11 21h2q.11 0 .22-.008V21H16a7 7 0 0 0 7-7v-4a7 7 0 0 0-7-7H4a3 3 0 0 0 0 6m11.83 10a3 3 0 0 0-1.214-3.529c.244-.434.384-.936.384-1.471 0-.53-.137-1.028-.379-1.46a3.4 3.4 0 0 0 .795-.593l1.898-1.898A1 1 0 0 0 15.9 8.635l-1.898 1.898A1.417 1.417 0 0 1 11.686 9h.011v-.027c.069-.162.17-.313.301-.445l3.194-3.193A1.14 1.14 0 0 1 16 5a5 5 0 0 1 5 5v4a5 5 0 0 1-5 5zM10 13h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2m1 4h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2m-.302-10H4a1 1 0 1 1 0-2h8.698zM9.624 9a3.43 3.43 0 0 0 .29 2h-.82a1 1 0 1 1 0-2z"
    />
  );
}
export default PointLeft;
