import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Image(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ImageIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M17.75 10a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5" />
      <path
        fillRule="evenodd"
        d="M4 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm16 2a1 1 0 0 1 1 1v9.086L18.914 13a2 2 0 0 0-2.828 0L14.5 14.586 9.914 10a2 2 0 0 0-2.828 0L3 14.086V6a1 1 0 0 1 1-1zm-2.5 9.414 3.5 3.5V18a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.086l5.5-5.5L13.086 16a2 2 0 0 0 2.828 0z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default Image;
