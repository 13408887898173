import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Transcript(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TranscriptIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M6 7a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm9 1a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1m-4-1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zm-1 5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1m-4-1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2z" />
      <path
        fillRule="evenodd"
        d="M5 3a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h4.54l3.942 4.598c1.208 1.41 3.518.556 3.518-1.301V17h1a4 4 0 0 0 4-4V6a3 3 0 0 0-3-3zM3 7a2 2 0 0 1 2-2h14a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2h-1a2 2 0 0 0-2 2v3.297l-3.941-4.599A2 2 0 0 0 9.54 15H5a2 2 0 0 1-2-2z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default Transcript;
