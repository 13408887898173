import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ArrowStopLeft(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ArrowStopLeftIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M11.71 15.88 8.83 13H21c.55 0 1-.45 1-1s-.45-1-1-1H8.83l2.88-2.88a.996.996 0 1 0-1.41-1.41L5.71 11.3a.996.996 0 0 0 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42M4 17V7c0-.55-.45-1-1-1s-1 .45-1 1v10c0 .55.45 1 1 1s1-.45 1-1"
    />
  );
}
export default ArrowStopLeft;
