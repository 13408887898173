import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ThumbsUp(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ThumbsUpIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M13.012 2a3 3 0 0 0-3 3v1.6a1.4 1.4 0 0 1-1.4 1.4 2.6 2.6 0 0 0-2.6 2.6V19a3 3 0 0 0 3 3h8.871a3 3 0 0 0 2.9-2.233l2.117-8A3 3 0 0 0 20 8h-3.988V5a3 3 0 0 0-3-3m-1 3a1 1 0 0 1 2 0v3a2 2 0 0 0 2 2H20a1 1 0 0 1 .967 1.256l-2.117 8a1 1 0 0 1-.967.744h-8.87a1 1 0 0 1-1-1v-8.4a.6.6 0 0 1 .6-.6 3.4 3.4 0 0 0 3.4-3.4zM4 10a1 1 0 0 0-2 0v10a1 1 0 1 0 2 0z"
    />
  );
}
export default ThumbsUp;
