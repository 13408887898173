import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function TechnicalWarning(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'TechnicalWarningIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M12.998 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0-7a1 1 0 1 0-2 0v4a1 1 0 0 0 2 0z" />
      <path d="M14.645 3.528c-1.177-2.037-4.117-2.037-5.293 0L1.412 17.28c-1.176 2.037.294 4.583 2.647 4.583h15.879c2.352 0 3.822-2.546 2.646-4.583l-7.94-13.752Zm6.175 14.77a1.02 1.02 0 0 1-.882 1.528H4.058a1.02 1.02 0 0 1-.882-1.528l7.94-13.751a1.02 1.02 0 0 1 1.764 0z" />
    </g>
  );
}
export default TechnicalWarning;
