import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Translation(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TranslationIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M5.113 2.742c.51-1.238 2.264-1.238 2.774 0l3.038 7.377a1 1 0 0 1-1.85.762L8.507 9.5H4.493l-.569 1.38a1 1 0 1 1-1.849-.76zM7.683 7.5 6.5 4.626 5.317 7.5z"
        clipRule="evenodd"
      />
      <path d="M11 14a1 1 0 0 1 1-1h3v-1.5a1 1 0 1 1 2 0V13h3a1 1 0 1 1 0 2c0 1.702-.979 3.269-2.228 4.448.782.36 1.558.552 2.228.552a1 1 0 1 1 0 2c-1.262 0-2.591-.433-3.765-1.093l-.228-.133C14.75 21.52 13.327 22 12 22a1 1 0 0 1 0-2c.682 0 1.468-.2 2.248-.558a8.6 8.6 0 0 1-1.062-1.214 1 1 0 0 1 1.627-1.163c.324.452.733.884 1.198 1.27C17.265 17.302 18 16.065 18 15h-6a1 1 0 0 1-1-1" />
    </g>
  );
}
export default Translation;
