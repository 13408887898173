import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Infant(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'InfantIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 8a3 3 0 0 1-2.608 2.975h.842a2 2 0 0 1 .992.263l3.27 1.868a1 1 0 1 1-.992 1.737l-1.505-.86v2.83l.95 2.846a1 1 0 0 1-1.898.633l-.952-2.857H10.9l-.952 2.856a1 1 0 1 1-1.897-.633L9 16.813v-2.83l-1.504.86a1 1 0 0 1-.992-1.737l3.269-1.868a2 2 0 0 1 .992-.263h.842A3 3 0 1 1 15 8m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-1 4v2.435h2V13z"
      clipRule="evenodd"
    />
  );
}
export default Infant;
