import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Export(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ExportIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M18 6a1 1 0 1 1-2 0V3h-6v5a1 1 0 0 1-1 1H4v12h12v-1a1 1 0 1 1 2 0v1a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7.828a2 2 0 0 1 .586-1.414l4.828-4.828A2 2 0 0 1 8.828 1H16a2 2 0 0 1 2 2zM4.828 7H8V3.828z"
        clipRule="evenodd"
      />
      <path d="M18.587 14.005H12c-.55 0-1-.45-1-1s.45-1 1-1h6.587l-.88-.89a.996.996 0 1 1 1.41-1.41l2.59 2.59c.39.39.39 1.02 0 1.41l-2.59 2.59a.996.996 0 1 1-1.41-1.41z" />
    </g>
  );
}
export default Export;
