import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Tip(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TipIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12 1a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1M6 12a6 6 0 1 1 12 0c0 1.803-.795 3.29-2.055 4.341-.548.458-.945 1.099-.945 1.813V20a3 3 0 1 1-6 0v-1.846c0-.714-.397-1.355-.945-1.813C6.795 15.291 6 13.803 6 12m6-4a4 4 0 0 0-4 4c0 1.16.493 2.102 1.336 2.806.615.513 1.21 1.26 1.493 2.194h2.342c.283-.934.878-1.68 1.493-2.194C15.507 14.102 16 13.161 16 12a4 4 0 0 0-4-4m1 11h-2v1a1 1 0 1 0 2 0zm7-7a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1M2 11a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zm3.487-6.925a1 1 0 0 0-1.414 1.414l.707.707a1 1 0 0 0 1.414-1.414zm14.437 1.414-.707.707a1 1 0 1 1-1.414-1.414l.707-.707a1 1 0 1 1 1.414 1.414"
    />
  );
}
export default Tip;
