import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Team(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'TeamIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M11 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0M9 7a2 2 0 1 0-4 0 2 2 0 0 0 4 0M3 19a4 4 0 0 1 8 0 1 1 0 1 0 2 0 6 6 0 0 0-12 0 1 1 0 1 0 2 0m14-8a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4m-1.6 6.332A4 4 0 0 1 21 19a1 1 0 1 0 2 0 6 6 0 0 0-8.4-5.5 1 1 0 1 0 .8 1.832"
    />
  );
}
export default Team;
