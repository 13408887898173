import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function VisibleOff(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'VisibleOffIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m3.51 12.095-.803-.803a1 1 0 0 0-1.414 1.415l.803.803q.38.381.782.726L1.293 15.82a1 1 0 1 0 1.414 1.414l1.795-1.795c.684.434 1.398.804 2.132 1.108l-.9 2.486a1 1 0 0 0 1.88.68l.918-2.535c.814.207 1.643.34 2.476.399a1 1 0 0 0-.008.127V20a1 1 0 1 0 2 0v-2.295q0-.065-.008-.127a14 14 0 0 0 2.476-.4l.918 2.536a1 1 0 1 0 1.88-.68l-.9-2.486a14 14 0 0 0 2.123-1.102l1.79 1.79a1 1 0 0 0 1.414-1.415l-1.579-1.579q.406-.348.79-.732l.803-.803a1 1 0 0 0-1.414-1.415l-.804.803c-4.688 4.689-12.29 4.689-16.978 0Z"
    />
  );
}
export default VisibleOff;
