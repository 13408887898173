import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Usb(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'UsbIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12.707 1.293a1 1 0 0 0-1.414 0L8.929 3.657a1 1 0 0 0 1.414 1.414L11 4.414V16a4 4 0 0 1-3.7-2.48 2 2 0 1 0-2 .353A6 6 0 0 0 11 18v1.268A2 2 0 0 0 12 23a2 2 0 0 0 1-3.732V15c2.66 0 4.915-1.73 5.702-4.127a2 2 0 1 0-2.001-.352A4 4 0 0 1 13 13V4.414l.657.657a1 1 0 0 0 1.414-1.414z"
    />
  );
}
export default Usb;
