import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Archive(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ArchiveIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M3 22a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2a1 1 0 0 1 0 2H3.026L3 20h.027l1.94-7.243a1.01 1.01 0 0 1 .89-.747Q5.927 12 6 12h12.996v-2h-.998a1 1 0 1 1 0-2h.998a2 2 0 0 1 2 2v2h1c.315 0 .597.146.78.375a.97.97 0 0 1 .192.87l-2.152 8.034a.985.985 0 0 1-1.037.718l-.074.003H2.999Zm17.598-8H6.608L5 20h13.991z"
        clipRule="evenodd"
      />
      <path d="M13 3a1 1 0 1 0-2 0v3.586l-.829-.829a1 1 0 0 0-1.414 1.415l2.535 2.535A1 1 0 0 0 12 10c.287 0 .545-.12.727-.313l2.515-2.515a1 1 0 0 0-1.414-1.415L13 6.585z" />
    </g>
  );
}
export default Archive;
