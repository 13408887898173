import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ThumbsDown(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ThumbsDownIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M11.039 22a3 3 0 0 0 3-3v-1.6a1.4 1.4 0 0 1 1.4-1.4 2.6 2.6 0 0 0 2.6-2.6V5a3 3 0 0 0-3-3H6.155a3 3 0 0 0-2.9 2.233l-2.117 8A3 3 0 0 0 4.038 16h4.001v3a3 3 0 0 0 3 3m1-3a1 1 0 1 1-2 0v-3a2 2 0 0 0-2-2H4.038a1 1 0 0 1-.967-1.256l2.117-8A1 1 0 0 1 6.155 4h8.884a1 1 0 0 1 1 1v8.4a.6.6 0 0 1-.6.6 3.4 3.4 0 0 0-3.4 3.4zM20 14a1 1 0 1 0 2 0V4a1 1 0 1 0-2 0z"
    />
  );
}
export default ThumbsDown;
