import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function UserPerson(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'UserPersonIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M17 6A5 5 0 1 1 7 6a5 5 0 0 1 10 0m-2 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0M5 22a7 7 0 1 1 14 0 1 1 0 1 0 2 0 9 9 0 1 0-18 0 1 1 0 1 0 2 0"
    />
  );
}
export default UserPerson;
