import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function LockedOff(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LockedOffIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M12 18.173a2.173 2.173 0 1 0 0-4.346 2.173 2.173 0 0 0 0 4.346" />
      <path d="M12 1a5 5 0 0 0-5 5 1 1 0 0 0 2 0 3 3 0 1 1 6 0v3H7a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h10.59A2.41 2.41 0 0 0 20 20.59V12a3 3 0 0 0-3-3V6a5 5 0 0 0-5-5M7 11h10a1 1 0 0 1 1 1v8.59a.41.41 0 0 1-.41.41H7a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1" />
    </g>
  );
}
export default LockedOff;
