import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function AedPadsError(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'AedPadsErrorIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M18.023 11.868A4 4 0 0 0 21 8V5a4 4 0 0 0-8 0v3a4 4 0 0 0 3.021 3.88c.134 2.66.875 4.412 1.851 5.528 1.073 1.226 2.351 1.591 3.128 1.591a1 1 0 1 0 0-2c-.223 0-.945-.133-1.622-.908-.598-.683-1.227-1.942-1.355-4.223M19 5v3a2 2 0 1 1-4 0V5a2 2 0 1 1 4 0"
        clipRule="evenodd"
      />
      <path d="M5 10a1 1 0 1 0-2 0v3a4 4 0 0 0 3.076 3.893c.253 1.45 1.116 2.563 2.24 3.383 1.315.96 3.028 1.56 4.71 1.948 3.36.776 6.95.776 7.964.776H21a1 1 0 1 0 0-2c-1 0-4.398-.003-7.525-.724-1.57-.363-2.981-.887-3.98-1.615-.705-.515-1.177-1.107-1.38-1.818A4 4 0 0 0 11 13v-3a1 1 0 1 0-2 0v3a2 2 0 1 1-4 0zm-.7-8.7a1.02 1.02 0 0 1 1.445 0L7.19 2.745 8.636 1.3a1.022 1.022 0 1 1 1.446 1.445L8.637 4.191l1.444 1.445A1.022 1.022 0 1 1 8.636 7.08L7.19 5.637 5.745 7.083A1.022 1.022 0 1 1 4.3 5.637l1.446-1.446-1.447-1.446a1.023 1.023 0 0 1 0-1.446Z" />
    </g>
  );
}
export default AedPadsError;
