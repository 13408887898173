import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Institute(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'InstituteIcon' },
      props
    ),
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M12.565 2.128a1 1 0 0 0-.971-.004l-9.077 5A1 1 0 0 0 3 9h18a1 1 0 0 0 .489-1.872zM17.17 7H6.888l5.184-2.856z"
        clipRule="evenodd"
      />
      <path d="M6 11a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1m6 0a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1m7 1a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0zm2 9a1 1 0 0 1-1 1H4a1 1 0 1 1 0-2h16a1 1 0 0 1 1 1" />
    </g>
  );
}
export default Institute;
