import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Support(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'SupportIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12 23c-2.677 0-5.132-.957-7.039-2.547l-.032.032a1 1 0 0 1-1.414-1.414l.032-.032A10.96 10.96 0 0 1 1 12c0-2.677.957-5.131 2.547-7.039l-.032-.032a1 1 0 0 1 1.414-1.414l.032.032A10.96 10.96 0 0 1 12 1c2.677 0 5.131.957 7.039 2.547l.032-.032a1 1 0 0 1 1.414 1.414l-.032.032A10.96 10.96 0 0 1 23 12c0 2.678-.957 5.132-2.547 7.04l.032.031a1 1 0 0 1-1.414 1.414l-.032-.032A10.96 10.96 0 0 1 12 23m5.617-3.968-1.425-1.425A6.97 6.97 0 0 1 12 19a6.97 6.97 0 0 1-4.192-1.394l-1.426 1.426A8.96 8.96 0 0 0 12 21a8.96 8.96 0 0 0 5.617-1.968m0-14.064A8.96 8.96 0 0 0 12 3a8.96 8.96 0 0 0-5.618 1.968l1.426 1.426A6.97 6.97 0 0 1 12 5c1.572 0 3.024.518 4.192 1.394zm-.01 2.84A6.97 6.97 0 0 1 19 12a6.97 6.97 0 0 1-1.394 4.192l1.426 1.426A8.96 8.96 0 0 0 21 12a8.96 8.96 0 0 0-1.968-5.618zm-1.438 1.43a1 1 0 0 1-1.408-1.407A4.98 4.98 0 0 0 12 7c-1.02 0-1.97.306-2.762.831A1 1 0 0 1 7.831 9.24 4.98 4.98 0 0 0 7 12c0 1.02.306 1.97.831 2.762a1 1 0 0 1 1.407 1.407A5 5 0 0 0 12 17c1.02 0 1.97-.306 2.761-.831a1 1 0 0 1 1.408-1.407A5 5 0 0 0 17 12c0-1.02-.306-1.97-.831-2.762m-9.775 6.954A6.97 6.97 0 0 1 5 12c0-1.572.518-3.024 1.394-4.192L4.968 6.382A8.96 8.96 0 0 0 3 12c0 2.125.736 4.078 1.968 5.618z"
    />
  );
}
export default Support;
