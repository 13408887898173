import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Palette(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PaletteIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12 22a9.7 9.7 0 0 1-3.875-.788 10.1 10.1 0 0 1-3.188-2.15 10.1 10.1 0 0 1-2.15-3.187A9.7 9.7 0 0 1 2 12q0-2.075.813-3.9a10.1 10.1 0 0 1 2.2-3.175A10.5 10.5 0 0 1 8.25 2.788 10 10 0 0 1 12.2 2q2 0 3.775.688a9.9 9.9 0 0 1 3.112 1.9 9.1 9.1 0 0 1 2.125 2.875A8.3 8.3 0 0 1 22 11.05q0 2.875-1.75 4.412Q18.5 17.002 16 17h-1.85q-.225 0-.313.125a.47.47 0 0 0-.087.275q0 .3.375.863t.375 1.287q0 1.25-.688 1.85T12 22m-5.5-9q.65 0 1.075-.425T8 11.5t-.425-1.075T6.5 10t-1.075.425T5 11.5t.425 1.075T6.5 13m3-4q.65 0 1.075-.425T11 7.5t-.425-1.075T9.5 6t-1.075.425T8 7.5t.425 1.075T9.5 9m5 0q.65 0 1.075-.425T16 7.5t-.425-1.075T14.5 6t-1.075.425T13 7.5t.425 1.075T14.5 9m3 4q.65 0 1.075-.425T19 11.5t-.425-1.075T17.5 10t-1.075.425T16 11.5t.425 1.075T17.5 13M12 20q.224 0 .363-.125a.42.42 0 0 0 .137-.325q0-.35-.375-.825-.375-.476-.375-1.425 0-1.05.725-1.675T14.25 15H16q1.65 0 2.825-.963Q20 13.075 20 11.05q0-3.025-2.313-5.038Q15.375 4 12.2 4 8.8 4 6.4 6.325T4 12q0 3.325 2.338 5.663Q8.675 19.999 12 20"
    />
  );
}
export default Palette;
