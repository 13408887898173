import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Group(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'GroupIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M8 16v3a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3h-3V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3zM5 4h8a1 1 0 0 1 1 1v3h-3a3 3 0 0 0-3 3v3H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1m11 6h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-3h3a3 3 0 0 0 3-3zm-2 0v3a1 1 0 0 1-1 1h-3v-3a1 1 0 0 1 1-1z"
    />
  );
}
export default Group;
