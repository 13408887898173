import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function PlaybackSpeed(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'PlaybackSpeedIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M11 2a1 1 0 0 1 1-1c6.075 0 11 4.925 11 11s-4.925 11-11 11a1 1 0 1 1 0-2 9 9 0 1 0 0-18 1 1 0 0 1-1-1m-3.686.045c.5-.235 1.076.036 1.265.555.189.52-.082 1.088-.577 1.334q-.512.255-.987.571c-.46.306-1.087.257-1.443-.166-.355-.423-.302-1.058.151-1.373q.753-.526 1.591-.92ZM2.966 5.723c.315-.453.95-.506 1.373-.15.423.355.472.983.166 1.442q-.316.475-.571.987c-.246.495-.815.766-1.334.577s-.79-.766-.555-1.265q.395-.838.92-1.591Zm-.816 4.538c-.544-.095-1.067.269-1.112.82a11 11 0 0 0 0 1.839c.045.55.568.914 1.112.819s.902-.614.868-1.166a9 9 0 0 1 0-1.146c.034-.552-.324-1.07-.868-1.166m-.105 6.425c-.235-.5.036-1.076.555-1.265.52-.189 1.088.082 1.334.577q.255.511.571.987c.306.46.257 1.087-.166 1.442-.423.356-1.058.303-1.373-.15a11 11 0 0 1-.92-1.591Zm3.678 4.348c-.453-.315-.506-.95-.15-1.373.355-.423.983-.473 1.442-.166q.475.316.987.571c.495.246.766.815.577 1.334s-.765.79-1.265.555a11 11 0 0 1-1.591-.92Z" />
      <path
        fillRule="evenodd"
        d="M9 7.869a1 1 0 0 1 1.555-.832l6.197 4.131a1 1 0 0 1 0 1.664l-6.197 4.132A1 1 0 0 1 9 16.132zM14.394 12 11 14.263V9.737z"
        clipRule="evenodd"
      />
    </g>
  );
}
export default PlaybackSpeed;
