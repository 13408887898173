import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function DragIndicator(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'DragIndicatorIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M8 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m2 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6-14a2 2 0 1 0 0-4 2 2 0 0 0 0 4m2 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-2 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
    />
  );
}
export default DragIndicator;
