import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ShockAutomated(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'ShockAutomatedIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.02 1.804A1 1 0 0 1 6 1h7a1 1 0 0 1 .894 1.447L11.618 7H15a1 1 0 0 1 .841 1.54l-9 14a1 1 0 0 1-1.822-.736L6.78 13H4a1 1 0 0 1-.98-1.196zM6.82 3l-1.6 8H8a1 1 0 0 1 .98 1.196l-.963 4.817L13.168 9H10a1 1 0 0 1-.894-1.447L11.382 3zm8.306 11.646c.524-1.198 2.224-1.198 2.748 0l3.042 6.953a1 1 0 0 1-1.832.802L18.47 21h-3.942l-.613 1.4a1 1 0 0 1-1.832-.8l3.042-6.954ZM15.404 19h2.192L16.5 16.495z"
      clipRule="evenodd"
    />
  );
}
export default ShockAutomated;
