import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function ListView(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ListViewIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M4 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2m4 0a1 1 0 0 0 0 2h12a1 1 0 1 0 0-2zm0 5a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2zm-1 6a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1m-4-5a1 1 0 1 1 2 0 1 1 0 0 1-2 0m1 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
    />
  );
}
export default ListView;
