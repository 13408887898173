import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Legend(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LegendIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.633 2.645a1.317 1.317 0 0 0-2.266 0L8.185 8.02A1.313 1.313 0 0 0 9.318 10h6.364a1.313 1.313 0 0 0 1.133-1.981zM14.48 8 12.5 4.657 10.52 8zM6 21a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4m8-4.5a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-5a1.5 1.5 0 0 1-1.5-1.5zm6 4.5h-4v-4h4z"
      clipRule="evenodd"
    />
  );
}
export default Legend;
