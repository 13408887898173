import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function Sun(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'SunIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M3.977 10.151c0-.385.44-.634 1.13-.634.655 0 1.321.192 2.44.69V8.702c-.972-.42-1.751-.6-2.61-.6C3.152 8.1 2 8.983 2 10.32c0 1.656 1.394 2.159 2.52 2.565l.046.016h3.196c-.378-1.029-1.5-1.443-2.424-1.785-.744-.275-1.361-.503-1.361-.966Zm1.943 3.752c0 .453-.396.713-1.13.713-.847 0-1.525-.26-2.632-.997v1.666l.024.013c.758.391 1.425.735 2.755.735 1.717 0 2.96-.861 2.96-2.346q-.001-.101-.01-.197H5.742c.112.116.178.25.178.413m10.11-.413h-2.047c-.333.66-1.017.934-1.744.934-.787 0-1.418-.34-1.718-.934H8.498c.36 1.76 1.828 2.543 3.764 2.543 1.842 0 3.4-.673 3.769-2.543Zm4.355 2.441H22V13.49h-3.715zM10.33 8.203v4.442q.001.133.017.257H8.423l-.002-.032a4 4 0 0 1-.011-.27V8.202zm5.784 4.384v-1.621l1.665 1.936h2.278l-4.09-4.7h-1.774v4.306q-.002.209-.03.394H16.1l.003-.04q.009-.134.01-.275Zm5.886.315h-1.92v-4.7H22z"
    />
  );
}
export default Sun;
