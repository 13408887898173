import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CloudDownload(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'CloudDownloadIcon',
      },
      props
    ),
    <g fill="currentColor">
      <path d="M17 10a4 4 0 0 0-8 0 1 1 0 0 1-1 1 2 2 0 0 0-2 2 1 1 0 0 1-1 1h-.5a1.5 1.5 0 0 0 0 3H5a1 1 0 1 1 0 2h-.5a3.5 3.5 0 0 1-.38-6.98 4.01 4.01 0 0 1 2.946-2.91A6.001 6.001 0 0 1 19 10v.028a4.5 4.5 0 0 1 2.693 7.643 1 1 0 0 1-1.419-1.41A2.5 2.5 0 0 0 18.5 12H18a1 1 0 0 1-1-.999z" />
      <path d="M13 12a1 1 0 0 1 1 1v5.585l1.828-1.828a1 1 0 0 1 1.415 1.415l-3.515 3.514a.997.997 0 0 1-1.435.021l-3.536-3.535a1 1 0 0 1 1.415-1.415L12 18.587V13a1 1 0 0 1 1-1" />
    </g>
  );
}
export default CloudDownload;
