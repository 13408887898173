import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function CoffeeBreak(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'CoffeeBreakIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M7 2a1 1 0 1 0-2 0c0 1.418.757 2.174 1.268 2.685l.025.025C6.783 5.2 7 5.442 7 6a1 1 0 1 0 2 0c0-1.413-.755-2.168-1.265-2.677l-.028-.028C7.218 2.807 7 2.562 7 2" />
      <path
        fillRule="evenodd"
        d="M5 9a2 2 0 0 0-2 2v7a5 5 0 0 0 5 5h4a5 5 0 0 0 5-5v-2h2a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2zm10 2H5v7a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3zm2 3h2v-3h-2z"
        clipRule="evenodd"
      />
      <path d="M11 1a1 1 0 0 1 1 1c0 .562.219.807.707 1.295l.028.028C13.245 3.833 14 4.587 14 6a1 1 0 0 1-2 0c0-.558-.217-.8-.707-1.29l-.025-.025C10.757 4.174 10 3.418 10 2a1 1 0 0 1 1-1" />
    </g>
  );
}
export default CoffeeBreak;
