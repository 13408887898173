import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function RadioButtonOff(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        'data-testid': 'RadioButtonOffIcon',
      },
      props
    ),
    <path
      fill="currentColor"
      d="M12.004 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16m-10 8c0 5.523 4.477 10 10 10s10-4.477 10-10-4.477-10-10-10-10 4.477-10 10"
    />
  );
}
export default RadioButtonOff;
