import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
type IconProps = SvgIconProps & { 'data-testid'?: string };
function PointRight(props: IconProps) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PointRightIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M20 9a3 3 0 1 0 0-6H8a7 7 0 0 0-7 7v4a7 7 0 0 0 7 7h2.78v-.008q.11.008.22.008h2a3 3 0 0 0 2.615-4.471 2.997 2.997 0 0 0 .974-4.045A3 3 0 0 0 17.735 9zM8.17 19H8a5 5 0 0 1-5-5v-4a5 5 0 0 1 5-5c.303 0 .594.12.808.335l3.194 3.193c.132.132.232.283.301.445V9h.011a1.418 1.418 0 0 1-2.316 1.533L8.1 8.635a1 1 0 0 0-1.414 1.414l1.898 1.898c.241.24.51.439.795.593C9.138 12.972 9 13.47 9 14c0 .535.14 1.037.385 1.471A3 3 0 0 0 8.171 19ZM14 13a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2zm-1 4a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2zm.302-10-2-2H20a1 1 0 1 1 0 2zm1.074 2h.53a1 1 0 1 1 0 2h-.82a3.43 3.43 0 0 0 .29-2"
    />
  );
}
export default PointRight;
